import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {NavLink} from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
// import style from "./style.modules.css"

export const MenuDesktop = (props) => {
    const {handleOpen, anchor, handleClose, data} = props;
    return (<Box
        // className={style.menu}
        sx={{
            flexGrow: 1, display: {xs: 'flex', md: 'none'}
        }}>
        <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpen}
            color="inherit"
        >
            <MenuIcon/>
        </IconButton>
        <Menu
            id="menu-appbar"
            anchorEl={anchor}
            // anchorEl={anchorElNav}
            anchorOrigin={{
                vertical: 'bottom', horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top', horizontal: 'left',
            }}
            open={Boolean(anchor)}
            onClose={handleClose}
            sx={{
                display: {xs: 'block', md: 'none'},
            }}
        >
            {data.map((page) => (
                <MenuItem
                    component={NavLink}
                    to={page[1]}
                    key={page[1]}
                    onClick={handleClose}>
                    {page[2]}
                    <Typography
                        textAlign="center">
                        {page[0]}
                    </Typography>
                </MenuItem>))}
        </Menu>
    </Box>)
}

export const MenuMobile = (props) => {
    const {handleClose, data} = props;
    return (<Box sx={{
        flexGrow: 1,
        display: {xs: 'none', md: 'flex'},
        justifyContent: 'end'
    }}>
        {data.map((page) => (
            <MenuItem
                component={NavLink}
                to={page[1]}
                key={page[1]}
                onClick={handleClose}
                sx={{
                    my: 3,
                    color: 'white',
                    display: 'block',
                    // '&:hover': {
                    //     background: "red",
                    // },
                    // background: 'red'
                }}
            >
                <Typography
                    textAlign="center">
                    {page[0]}
                </Typography>
            </MenuItem>
        ))}
    </Box>)
}