//Форма входа

import React, {useContext, useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import {FormControl} from "@mui/material";
import {validateEmail} from "../validation/validate_email";
import {IsInputsNotEmpty} from "../validation/is_inputs_not_empty";
import {useNavigate} from "react-router-dom";
import {UserContext} from "./userContext";
import {fetchLogin} from "../../api/user";
import {PopUp} from "../modules/pop_up";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function InfoChangeEmailConfirm() {
    // Title страницы
    useEffect(() => {
        document.title = 'Проверьте почту! - whatsapp-start.com'
    })

    return (<div>
        <h2 style={{marginBottom: '0px'}}>Проверьте почту! </h2>
        <p style={{marginBottom: '24px'}}>Мы отправили ссылку для изменения адреса почты.</p>
        <Button
            // onClick={handleClickOpen}
            href={'/profile'}
            variant="outlined"
            sx={{
                marginTop: 1,
                marginBottom: 2,
                height: 56,
                width: {xs: '100%', sm: 380}
            }}
        >Назад
            <ArrowBackIcon sx={{marginLeft: 1}}/>
        </Button>
    </div>);
}

export default InfoChangeEmailConfirm;

