import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export const Preloader = () => {
    return (
        <Box sx={{
            position: 'absolute',
            top: '40%',
            left: 'calc(50% - 28px)'
        }}>
            <CircularProgress/>
        </Box>
    );
}