import React, {useEffect, useState} from 'react';
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import CreateSharpIcon from '@mui/icons-material/CreateSharp';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import {Preloader} from "./modules/preloader";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import {fetchDeleteTemplate, fetchEditTemplate, fetchGetAllTemplates} from "../api/templates";
import {useNavigate, useSearchParams} from "react-router-dom";
import Divider from '@mui/material/Divider';
import {PopUp} from "./modules/pop_up";
import {Checkbox, FormControlLabel,} from "@mui/material";
import {SendRounded} from "@mui/icons-material";

function TemplatesMessages() {
    const navigate = useNavigate()  // Переадресация
    const [popUpVisible, setPopUpVisible] = useState(false);  // Pop-up окно
    const [confirmPopUpVisible, setConfirmPopUpVisible] = useState(false);
    const [templatesMsg, setTemplatesMsg] = useState([]);  // Заполнение шаблонов при загрузке
    const [loading, setLoading] = useState(false);  // Прелоудер
    const [jwt, setJwt] = useState('');  // Токен
    const [refresh, setRefresh] = useState(false);
    const [error, setError] = useState("");
    const [currentTemplate, setCurrentTemplate] = useState('');
    const [values, setValues] = useState({});


    const popUpClose = () => {
        setPopUpVisible(false);
    };
    const handlePopUpClose = () => {
        setPopUpVisible(false)
    };
    const handleConfirmPopUpOpen = (id) => {
        setCurrentTemplate(id)
        setConfirmPopUpVisible(true)
    };
    const handleConfirmPopUpClose = () => {
        setConfirmPopUpVisible(false)
    };

    const handleSwitchIsSelectedDefault = (event) => {
        let idCurrentTemplate = event.target.id
        let checked = event.target.checked
        if (checked) {
            setValues({idTemplate: idCurrentTemplate, is_default: {is_default: true}});
        } else {
            setValues({idTemplate: idCurrentTemplate, is_default: {is_default: false}});
        }
    };

    const closePopUpOnKeyEnter = (event) => {
        if (event.keyCode === 13) {
            setPopUpVisible(false)
        }
    }

    // DELETE - удаление шаблона
    const deleteTemplate = (jwt, idTemplate) => {
        fetchDeleteTemplate(jwt, idTemplate)
            .then((res) => {
                if (res.deleted) {
                    console.log("Успешно удалено")
                    console.log(popUpVisible)
                    setConfirmPopUpVisible(false)
                    setRefresh(true)
                } else {
                    console.log("Не удалено")
                    setError(res.errorMessageArray)
                    setPopUpVisible(true)
                }
            })
    }

    useEffect(() => {
        document.title = 'Шаблоны сообщений для отправки в WhatsApp - chat-starter.com'  // Title страницы
        const token = localStorage.getItem("jwt_access")   // Есть ли токен или нет?
        if (token) {
            setJwt('Bearer ' + token)
        } else {
            navigate('/login')
        }
    }, [])

    // [PATCH] - При изменении values - отправляем запрос на изменение шаблона
    useEffect(() => {
        if (jwt && Object.keys(values).length !== 0) {
            fetchEditTemplate(jwt, values.idTemplate, values.is_default)
                .then((res) => {
                    if (res.template) {
                        console.log("res.template", res.template)
                        console.log("values", values)
                        // setTemplatesMsg(templatesMsg)
                        setLoading(true)
                        setRefresh(true)
                    } else if (res.errorMessageArray) {
                        setError(res.errorMessageArray)
                        setPopUpVisible(true)
                    }
                });
            // setRefresh(true)
        }
    }, [jwt, values]);

    // [GET] - запрос на получение всех шаблонов пользователя
    useEffect(() => {
        if (jwt) {
            fetchGetAllTemplates(jwt)
                .then((response) => {
                    if (response.templates) {
                        setTemplatesMsg(response.templates)
                        setLoading(true)
                    } else if (response.errorMessageArray) {
                        setLoading(true)
                        setError(response.errorMessageArray)
                        setPopUpVisible(true)
                    }
                })
            setRefresh(false)
        }
    }, [jwt, refresh])

    return (<>
        {loading === true ? (<>
            <div>
                <h1>Шаблоны сообщений</h1>
                <p>
                    Добавьте или выберите шаблон сообщения, с которого вы хотите начать общение.
                </p>
                <Button
                    href={'/add_template'}
                    type={"submit"}
                    variant="contained"
                    sx={{
                        marginTop: 1,
                        marginBottom: 2,
                        width: {xs: '100%', sm: 380}, height: 56
                    }}
                >
                    Добавить
                    <AddIcon sx={{marginLeft: 1}}/>
                </Button>
                {templatesMsg.map(item => {
                    return (<Box
                        key={item.id}
                        sx={{
                            display: 'flex', flexDirection: 'column'
                        }}
                    >
                        <Box
                            sx={{
                                background: "#ededed",
                                borderRadius: 4,
                                paddingTop: 3,
                                paddingLeft: 3,
                                paddingRight: 3,
                                paddingBottom: 1,
                                marginTop: 4,
                                marginBottom: 3,
                                border: "solid",
                                borderColor: "#e3e3e3",
                                borderWidth: '1px',
                                cursor: "pointer"
                            }}
                            onClick={() => {
                                navigate('/edit_template/' + item.id)
                            }}
                        >

                            <Divider>
                                <b>"{item.name_template}</b>"
                            </Divider>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    whiteSpace: 'pre-wrap'
                                }}
                            >
                                <p><i>" {item.text_template} "</i></p>
                            </Box>
                        </Box>
                        <Button
                            href={'/?defaultTemplate=' + item.id}
                            type={"submit"}
                            variant="outlined"
                            sx={{
                                marginTop: 0,
                                marginBottom: 1,
                                width: {xs: '100%', sm: 380}, height: 56
                            }}
                        >
                            Отправить сообщение
                            <SendRounded sx={{marginLeft: 1}}/>
                        </Button>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id={item.id.toString()}
                                    value={item.is_default}
                                    checked={item.is_default}
                                    onChange={handleSwitchIsSelectedDefault}
                                />
                            }
                            label="Использовать по умолчанию"
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                height: '20px',
                                marginBottom: '16px'
                            }}
                        >
                            <CreateSharpIcon sx={{marginRight: '8px'}}/>
                            <Link
                                color={'black'}
                                href={'/edit_template/' + item.id}
                                underline={'none'}
                            >
                                Редактировать
                            </Link>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                height: '20px',
                                marginBottom: '16px'
                            }}
                        >
                            <HighlightOffRoundedIcon sx={{marginRight: '8px'}}/>
                            <Link
                                color={'black'}
                                underline={'none'}
                                id={item.id}
                                onClick={(el) => {
                                    handleConfirmPopUpOpen(el.target.id)
                                }}
                                sx={{
                                    cursor: "pointer"
                                }}
                            >
                                Удалить
                            </Link>
                        </Box>
                    </Box>)
                })}
                <PopUp
                    handleClose={popUpClose}
                    open={popUpVisible}
                    title={"Что-то пошло не так 😢"}
                    closePopUpOnEnterOrEscape={closePopUpOnKeyEnter}
                >
                    {error}
                </PopUp>

                {/*Старый*/}
                {/*<Dialog*/}
                {/*    disableScrollLock*/}
                {/*    open={confirmPopUpVisible}*/}
                {/*    onClose={handlePopUpClose}*/}
                {/*    aria-labelledby="alert-dialog-title"*/}
                {/*    aria-describedby="alert-dialog-description"*/}
                {/*>*/}
                {/*    <DialogTitle id="alert-dialog-title">*/}
                {/*        {"Вы уверены?"}*/}
                {/*    </DialogTitle>*/}
                {/*    <DialogContent>*/}
                {/*        <DialogContentText id="alert-dialog-description">*/}
                {/*            Подтвердите удаление шаблона.*/}
                {/*        </DialogContentText>*/}
                {/*    </DialogContent>*/}
                {/*    <DialogActions>*/}
                {/*        <Button*/}
                {/*            onClick={handleConfirmPopUpClose}*/}
                {/*            autoFocus*/}
                {/*        >*/}
                {/*            Нет*/}
                {/*        </Button>*/}
                {/*        <Button*/}
                {/*            onClick={() => deleteTemplate(jwt, currentTemplate)}>*/}
                {/*            Да*/}
                {/*        </Button>*/}
                {/*    </DialogActions>*/}
                {/*</Dialog>*/}

                {/*Новый*/}
                <Dialog
                    disableScrollLock
                    open={confirmPopUpVisible}
                    onClose={handleConfirmPopUpClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Вы уверены?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Подтвердите удаление шаблона.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleConfirmPopUpClose}>Нет</Button>
                        <Button
                            onClick={() => deleteTemplate(jwt, currentTemplate)}>
                            Да
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>) : (<><Preloader/></>)}
    </>);
}

export default TemplatesMessages;