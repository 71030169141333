import {useEffect} from 'react';
import Button from "@mui/material/Button";

function RemindPasswordEmailHaveSend() {
    // Title страницы
    useEffect(() => {
        document.title = 'Письмо отправлено - chat-starter.com'
    })

    return (<div>
        <h2 style={{marginBottom: '0px'}}>✅ Письмо отправлено! </h2>
        <p style={{marginBottom: '24px'}}>Проверьте Вашу почту 📧, мы отправили на него письмо со
            ссылкой для восстановления пароля.</p>
        <Button href="/login" type={"submit"}
                sx={{
                    marginTop: 1, marginBottom: 2, width: {xs: '100%', sm: 380}, height: 56
                }}
                variant="contained">Войти
        </Button>
    </div>);
}

export default RemindPasswordEmailHaveSend;