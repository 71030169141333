//Форма входа

import {useContext, useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import {FormControl} from "@mui/material";
import {validateEmail} from "../validation/validate_email";
import {IsInputsNotEmpty} from "../validation/is_inputs_not_empty";
import {useNavigate} from "react-router-dom";
import {UserContext} from "./userContext";
import {fetchLogin} from "../../api/user";
import {PopUp} from "../modules/pop_up";

function ActivationAlreadyActivated() {
    const navigate = useNavigate()
    // Валидация полей формы
    const [isValidateEmail, SetValidateEmail] = useState(false)
    const [isValidatePassword, SetValidatePassword] = useState(false)
    const [helperTextEmail, SetHelperTextEmail] = useState('')
    const [helperTextPassword, SetHelperTextPassword] = useState('')
    const {setAuthenticated} = useContext(UserContext);
    const [popUpVisible, setPopUpVisible] = useState(false);
    const [error, setError] = useState("");
    // Получение значений из полей формы
    const [values, setValues] = useState('');
    const [jwt, setJwt] = useState(false);
    useEffect(() => {
        document.title = 'Регистрация нового пользователя - пользователь уже активирован - chat-starter.com'  // Title страницы
        const token = localStorage.getItem("jwt_access")
        if (token) {
            navigate('/')
        }
    }, [])

    useEffect(() => {
        if (jwt) {
            navigate('/templates_messages')
        }
    }, [jwt])
    const popUpOpen = () => {
        setPopUpVisible(true);
    };
    const popUpClose = () => {
        setPopUpVisible(false);
    };
    // Очистка error состояние у инпутов при фокусировании
    const clearError = () => {
        SetValidateEmail(false)
        SetValidatePassword(false)
        SetHelperTextEmail('')
        SetHelperTextPassword('')
    }
    // Переадресация
    const SetValuesOnChange = (event) => {
        setValues({...values, [event.target.id]: event.target.value});
        if (event.keyCode === 13) {
            SendForm(values)
        }
    };
    // Отправка формы (проверяем заполненность и валидность полей)
    const CheckForm = (values) => {
        if (IsInputsNotEmpty(values, ['email', 'password'])) {
            // console.log('Поля формы заполнены')
            if (validateEmail(values['email'])) {
                // console.log('Валидация прошла успешно')
                return true
            } else {
                SetValidateEmail(true)
                SetHelperTextEmail('Не корректный e-mail')
                return false
            }
        } else {
            if (!(values['email'])) {
                SetValidateEmail(true)
                SetHelperTextEmail('Обязательное поле')
                return false
            }
            if (!(values['password'])) {
                SetValidatePassword(true)
                SetHelperTextPassword('Обязательное поле')
                return false
            }
        }
    }

    //Функция отправки данных из формы
    const SendForm = (values) => {
        let result_validation_form = CheckForm(values)
        if (result_validation_form === true) {
            let data = {
                email: values.email,
                password: values.password,
            }
            fetchLogin(data)
                .then((res) => {
                    if (res.ok) {
                        setAuthenticated(true)
                        setJwt(true)
                    } else if (res.message) {
                        setError(res.message)
                        popUpOpen()
                    }
                });
        }
    }

    const ClosePopUpOnKeyEnter = (event) => {
        if (event.keyCode === 13) {
            setPopUpVisible(false)
        }
    }

    return (<>
        <PopUp
            handleClose={popUpClose}
            open={popUpVisible}
            title={"Что-то пошло не так 😢"}
            closePopUpOnEnterOrEscape={ClosePopUpOnKeyEnter}
        >
            {error}
        </PopUp>

        <Box sx={{
            display: 'flex', justifyContent: 'center', flexDirection: 'column',
        }}>
            <h1 style={{marginBottom: '0px'}}>⚠️ Аккаунт уже активирован! </h1>
            <p style={{marginBottom: '24px'}}>
                Войдите или воспользуйтесь функцией восстановления пароля.
            </p>
            <Box sx={{
                display: 'flex', justifyContent: 'center', flexDirection: 'column',
            }}>
                <Box sx={{
                    display: 'flex', justifyContent: 'center', flexDirection: 'column',
                }}>
                    <FormControl>
                        <TextField
                            onKeyUp={SetValuesOnChange}
                            onFocus={clearError}
                            error={isValidateEmail}
                            helperText={helperTextEmail}

                            id={"email"}
                            type={"email"}
                            margin={"dense"}
                            sx={{
                                marginBottom: 1, width: {xs: '100%', sm: 380}
                            }}
                            required={true}

                            label="E-mail"
                            defaultValue=""
                        />
                        <TextField
                            onKeyUp={SetValuesOnChange}
                            onFocus={clearError}
                            error={isValidatePassword}
                            helperText={helperTextPassword}

                            id={"password"}
                            type={"password"}
                            margin={"dense"}
                            sx={{
                                marginBottom: 3, width: {xs: '100%', sm: 380}
                            }}
                            required={true}
                            label="Пароль"
                            defaultValue=""
                        />
                        <Button
                            onClick={() => {
                                // setMenu()
                                SendForm(values)
                            }}

                            type={"submit"}
                            sx={{
                                marginBottom: 1,
                                width: {xs: '100%', sm: 380},
                                height: 56
                            }}
                            variant="contained">Войти
                        </Button>
                    </FormControl>
                </Box>
            </Box>
        </Box>
        <Box sx={{
            display: 'flex',
            padding: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 2,
            width: {xs: '100%', sm: 380},
            height: 56
        }}>
            <div>
                <Link underline="none" href="/sign_in">Регистрация
                </Link>
            </div>
            <div>
                <Link underline="none" href="/remind_password">Забыли пароль?
                </Link>
            </div>
        </Box>
    </>)
}

export default ActivationAlreadyActivated;

