import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";


export const LogoDesktop = () => {
    const navigate = useNavigate()
    return (<Typography
        variant="h6"
        noWrap
        onClick={() => navigate('/')}

        sx={{
            mr: 2,
            display: {xs: 'none', md: 'flex'},
            fontFamily: 'Jost',
            fontWeight: 700,
            letterSpacing: '.1rem',
            color: 'white',
            cursor: 'pointer'
        }}
    >
        CHAT <span style={{color: '#24c76f'}}>STARTER</span>
    </Typography>)
}

export const LogoMobile = () => {
    const navigate = useNavigate()
    return (<Typography
        variant="h5"
        noWrap
        component="a"
        href="/"
        sx={{
            mr: 2,
            display: {xs: 'flex', md: 'none'},
            flexGrow: 1,
            fontFamily: 'Jost',
            fontWeight: 700,
            letterSpacing: '.1rem',
            color: 'inherit',
            textDecoration: 'none',
        }}
    >
        CHAT <span style={{color: '#24c76f'}}>STARTER</span>
    </Typography>)
}