//Форма ввода телефона и отправки сообщения в WhatsAapp

import React, {createRef, useEffect, useState} from "react";

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ru from 'react-phone-input-2/lang/ru.json'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {FormControl, InputLabel, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {fetchGetAllTemplates} from "../../api/templates";
import {useNavigate, useSearchParams} from "react-router-dom";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import {useParams} from "react-router";
import {SendRounded} from "@mui/icons-material";

export default function SelectTemplate() {
    const navigate = useNavigate()  // Переадресация
    const [jwt, setJwt] = useState('');  // Токен
    const [popUpVisible, setPopUpVisible] = useState(false);
    const [values, setValues] = useState({});
    const [error, setError] = useState("");
    let inputNumber = createRef()
    const [tel, setTel] = useState('')
    const [currentUrl, setCurrentUrl] = useState('')
    const [loading, setLoading] = useState(false);  // Прелоудер
    const [refresh, setRefresh] = useState(false);
    const [templatesMsg, setTemplatesMsg] = useState([]);
    const [idCurrentTemplate, setIdCurrentTemplate] = useState("");
    const [textCurrentTemplate, setTextCurrentTemplate] = useState("");
    const [phone, setPhone] = useState('+ ');
    let preferredCountries = ['ru', 'by', 'ua', 'kz']
    // let {defaultTemplate} = useParams()
    const [searchParams, setSearchParams] = useSearchParams();
    let defaultTemplateId = searchParams.get("defaultTemplate")

    let inputStyle = {
        height: '56px',
        width: '100%',
    }
    // let priority = {'ru': 1, 'by': 1, 'ua': 2, 'kz': 3}
    // let url = 'whatsapp://send?phone='
    let url = 'whatsapp://send/?phone='
    // let url = 'https://api.whatsapp.com/send/?phone='

    const handleSelectTemplate = event => {
        setIdCurrentTemplate(event.target.value);
        let text = templatesMsg.find((el) => el.id === event.target.value).text_template
        setTextCurrentTemplate(text)
    };
    const handleChangeTel = value => {
        setTel(value)
    };

    useEffect(() => {
        if (tel) {
            let link = url + tel + "&text="
            let text = encodeURIComponent(textCurrentTemplate)
            setCurrentUrl(link + text)
        }
    }, [tel, textCurrentTemplate]);


    // Очищаем значение в input
    useEffect(() => {
        setPhone(' ')
        document.title = 'Шаблоны сообщений - whatsapp-start.com'  // Title страницы
        const token = localStorage.getItem("jwt_access")   // Есть ли токен или нет?
        if (token) {
            setJwt('Bearer ' + token)
        }


    }, []);

    // [GET] - запрос на получение всех шаблонов пользователя
    useEffect(() => {
        if (jwt) {
            fetchGetAllTemplates(jwt)
                .then((response) => {
                    if (response.templates) {
                        setTemplatesMsg(response.templates)
                        console.log(response.templates)
                        if (defaultTemplateId) {
                            console.log("defaultTemplate", defaultTemplateId)
                            let currTemplate = response.templates.find((el) => el.id.toString() === defaultTemplateId)
                            console.log(currTemplate)
                            setIdCurrentTemplate(currTemplate.id)
                            setTextCurrentTemplate(currTemplate.text_template)
                        } else {
                            setIdCurrentTemplate(response.templates[0].id)
                            setTextCurrentTemplate(response.templates[0].text_template)
                        }
                        setLoading(true)
                    } else if (response.errorMessageArray) {
                        setLoading(true)
                        setError(response.errorMessageArray)
                        setPopUpVisible(true)
                    }
                })
            setRefresh(false)
        }
    }, [jwt, refresh])

    return (<>
        <Box display={"flex"}
             justifyContent={"left"}
             flexDirection={{xs: 'column'}}
             sx={{
                 width: {xs: '100%', sm: '380px'},
                 marginBottom: 2,
                 // marginRight: {xs: 0, md: 2}
             }}>


            <Box sx={{
                width: '100%',
                marginBottom: 2,
                marginRight: {xs: 0, md: 2}
            }}>
                <PhoneInput
                    localization={ru}
                    enableClickOutside={true}
                    jumpCursorToEnd={true}
                    onFocus={event => setPhone('+')}
                    onChange={handleChangeTel}
                    value={phone}
                    country={'ru'}
                    placeholder='Например 79211234567'
                    inputRef={inputNumber}
                    inputStyle={inputStyle}
                    inputProps={{
                        name: 'phone', required: true, // autoFocus: true
                    }}
                    disableDropdown={false}
                    preferredCountries={preferredCountries}
                    // onChange={setUrl}
                    // prefix={'+'}
                    // value={this.state.phone}
                    // onChange={phone => this.setState({ phone })}
                    // priority={priority}
                    // copyNumbersOnly={false}
                    // disableInitialCountryGuess={true}
                    // disableCountryGuess={true}
                    // enableAreaCodes={true}
                    // disableCountryCode={true}
                />

            </Box>
            {templatesMsg.length > 0 ?
                (<>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Шаблон</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            // value={age}
                            label="Шаблон"
                            // onChange={handleChange}
                            value={idCurrentTemplate}
                            onChange={handleSelectTemplate}
                            sx={{
                                height: 56, width: '100%', // marginRight: 2,
                                marginBottom: 2,
                                order: {xs: '1', sm: '3'}
                            }}
                        >
                            {templatesMsg.map((item) => {
                                return (<MenuItem
                                        key={item.id}
                                        value={item.id}>
                                        {item.name_template}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </>) : (<Button
                    href={'/templates_messages'}
                    variant="outlined"
                    sx={{
                        marginBottom: 2,
                        width: {xs: '100%', sm: 380},
                        height: 56
                    }}
                >добавить шаблон ...

                </Button>)}
            <Box>
                <Box display={"flex"} flexDirection={{xs: 'column', sm: 'row'}}>
                    <Button
                        href={currentUrl}
                        sx={{
                            height: 56, width: '100%', // marginRight: 2,
                            marginBottom: 2,
                            order: {xs: '1', sm: '3'}
                        }}
                        variant="contained">Отправить<SendRounded fontSize="small" sx={{marginLeft: 1}}/></Button>
                    <Button onClick={event => setPhone('+ ')}
                            sx={{
                                height: 56,
                                width: '100%',
                                marginBottom: 2,
                                marginRight: {xs: 0, sm: 2},
                                order: '2'
                            }}
                            variant="outlined">Очистить</Button>
                </Box>
            </Box>

        </Box>
    </>)
        ;
}