//Форма входа

import {useContext, useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import {FormControl} from "@mui/material";
import {validateEmail} from "../validation/validate_email";
import {IsInputsNotEmpty} from "../validation/is_inputs_not_empty";
import {useNavigate} from "react-router-dom";
import {UserContext} from "./userContext";
import {fetchLogin} from "../../api/user";
import {PopUp} from "../modules/pop_up";
import * as events from "events";

function Login() {
    const navigate = useNavigate()
    const [isValidateEmail, SetValidateEmail] = useState(false)
    const [isValidatePassword, SetValidatePassword] = useState(false)
    const [helperTextEmail, SetHelperTextEmail] = useState('')
    const [helperTextPassword, SetHelperTextPassword] = useState('')
    const {setAuthenticated} = useContext(UserContext);
    const [popUpVisible, setPopUpVisible] = useState(false);
    const [error, setError] = useState("");
    const [values, setValues] = useState('');  // Получение значений из полей формы
    const [jwt, setJwt] = useState(false);

    const popUpOpen = () => {
        setPopUpVisible(true);
    };
    const popUpClose = () => {
        setPopUpVisible(false);
    };
    // Очистка error состояние у инпутов при фокусировании
    const clearError = () => {
        SetValidateEmail(false)
        SetValidatePassword(false)
        SetHelperTextEmail('')
        SetHelperTextPassword('')
    }
    const setValuesOnChange = (event) => {
        setValues({...values, [event.target.id]: event.target.value});
    };
    const closePopUpOnKeyEnter = (event) => {
        if (event.keyCode === 13) {
            setPopUpVisible(false)
        }
    }
    const sendFormIfEnter = (event) => {
        if (event.keyCode === 13) {
            sendForm(values)
        }
    }

    // Отправка формы (проверяем заполненность и валидность полей)
    const CheckForm = (values) => {
        if (IsInputsNotEmpty(values, ['email', 'password'])) {
            // console.log('Поля формы заполнены')
            if (validateEmail(values['email'])) {
                // console.log('Валидация прошла успешно')
                return true
            } else {
                SetValidateEmail(true)
                SetHelperTextEmail('Не корректный e-mail')
                return false
            }
        } else {
            if (!(values['email'])) {
                SetValidateEmail(true)
                SetHelperTextEmail('Обязательное поле')
                return false
            }
            if (!(values['password'])) {
                SetValidatePassword(true)
                SetHelperTextPassword('Обязательное поле')
                return false
            }
        }
    }

    //Функция отправки данных из формы
    const sendForm = (values) => {
        let result_validation_form = CheckForm(values)
        if (result_validation_form === true) {
            let data = {
                email: values.email,
                password: values.password,
            }
            fetchLogin(data)
                .then((res) => {
                    if (res.ok) {
                        setAuthenticated(true)
                        setJwt(true)
                    } else if (res.message) {
                        setError(res.message)
                        popUpOpen()
                    }
                });
        }
    }

    // Если токен уже есть в localStorage - то переадресуем
    useEffect(() => {
        document.title = 'Вход - chat-starter.com'
        const token = localStorage.getItem("jwt_access")
        if (token) {
            navigate('/')
        }
    }, [])

    // Если вошли успешно - то переадресуем
    useEffect(() => {
        if (jwt) {
            navigate('/')
        }
    }, [jwt])


    return (<>
        <PopUp
            handleClose={popUpClose}
            open={popUpVisible}
            title={"Что-то пошло не так 😢"}
            closePopUpOnEnterOrEscape={closePopUpOnKeyEnter}
        >
            {error}
        </PopUp>
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
        }}>
            <h2 style={{marginBottom: '0px'}}>✅ Ваш аккаунт активирован.</h2>
            <p style={{marginBottom: '24px'}}>Что бы продолжить пользоваться сервисом - войдите.</p>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
            }}>
                <FormControl>
                    <TextField
                        onKeyUp={sendFormIfEnter}
                        onChange={setValuesOnChange}
                        onFocus={clearError}
                        error={isValidateEmail}
                        helperText={helperTextEmail}
                        id={"email"}
                        type={"email"}
                        required={true}
                        label="E-mail"
                        defaultValue=""
                        sx={{
                            marginBottom: 2, width: {xs: '100%', sm: 380}
                        }}
                    />
                    <TextField
                        onKeyUp={sendFormIfEnter}
                        onChange={setValuesOnChange}
                        onFocus={clearError}
                        error={isValidatePassword}
                        helperText={helperTextPassword}
                        id={"password"}
                        type={"password"}
                        sx={{
                            marginBottom: 3, width: {xs: '100%', sm: 380}
                        }}
                        required={true}
                        label="Пароль"
                        defaultValue=""
                    />
                    <Button
                        onClick={() => {
                            // setMenu()
                            sendForm(values)
                        }}

                        type={"submit"}
                        sx={{
                            marginBottom: 1,
                            width: {xs: '100%', sm: 380},
                            height: 56
                        }}
                        variant="contained">Войти
                    </Button>
                </FormControl>
            </Box>
        </Box>
        <Box sx={{
            display: 'flex',
            padding: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 2,
            width: {xs: '100%', sm: 380},
            height: 56
        }}>
            <div>
                <Link underline="none" href="/sign_in">Регистрация
                </Link>
            </div>
            <div>
                <Link underline="none" href="/remind_password">Забыли пароль?
                </Link>
            </div>
        </Box>
    </>)
}

export default Login;

