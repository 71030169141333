// Валидация password Минимальные требования к паролю:
// 8 символов на латинице, одна заглавная, одна строчная буква, 2 цифры
const passwordValidator = require('password-validator');
const schema_password = new passwordValidator();

// Минимальное кол-во символов
const MIN_LEN_PASSWORD = 8

// Максимальное кол-во символов
const MAX_LEN_PASSWORD = 100

// Минимальное кол-во цифр
const QTY_DIGITS = 1

schema_password
    .is().min(MIN_LEN_PASSWORD)
    .is().max(MAX_LEN_PASSWORD)
    .has().digits(QTY_DIGITS)// Must have at least 1 digits
    .has().letters(1)
export const isPasswordValid = (password) => schema_password.validate(password)
