import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


export const PopUp = (props) => {
    const {handleClose, open, title, closePopUpOnEnterOrEscape, children} = props
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onKeyUp={closePopUpOnEnterOrEscape}
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                {children && children.map((el, index) => (<DialogContentText key={index}>{el}</DialogContentText>))}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} autoFocus>ОК</Button>
            </DialogActions>
        </Dialog>
    );
}