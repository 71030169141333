//TODO Полное дублирование кода страницы login с одним лишь изменением,
// что в начале - чиститься localstorage. Это не правильно.

import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";

import {UserContext} from "./userContext";

function Logout() {
    localStorage.clear()
    const { setAuthenticated } = useContext(UserContext);
    const navigate = useNavigate()

    useEffect(() => {
        document.title = 'Выход - chat-starter.com'  // Title страницы
        setAuthenticated(false)
        navigate("/login")
    }, []);

    return (<>

    </>)
}

export default Logout;

