import {useEffect} from 'react';

import {useNavigate} from 'react-router-dom';
import {useParams} from "react-router";

function Activate(props) {
    const navigate = useNavigate();
    const {uid,token} = useParams();  // Параметры url
    const host_name = process.env.REACT_APP_HOST;
    const url_post = host_name + '/api/v1/auth/users/activation/';


    useEffect(() => {
        document.title = 'Активация нового пользователя - chat-starter.com'  // Title страницы
    });

    useEffect(() => {
        let data = {
            uid: uid,
            token: token,
        }
        console.log(data)
        fetch(url_post, {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.status === 204) {
                    response.json();
                    console.log(response.status);
                    navigate("/activation_done")
                } else if (response.status === 403) {
                    // Пользователь уже зарегистрирован
                    console.log('Ответ сервера:', response.status);
                    response.json().then(data => {
                        console.log(data)
                    });
                    navigate("/activation_already_activated")
                } else {
                    // С ссылкой что-то не так (не верные параметры или еще что-то)
                    console.log('Ответ сервера');
                    console.log(response.status);
                    console.log(response.text());
                    navigate("/activation_wrong")
                }
            })
            .catch(error => {
                // Обработка ошибки
                console.error('Произошла ошибка:', error);
            });
    }, [uid, token]);
}

export default Activate;