// Добавление шаблона

import React, {useEffect, useState} from 'react';
import axios from "axios";
import Button from "@mui/material/Button";
import {Checkbox, FormControl, FormControlLabel} from "@mui/material";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import DoneIcon from '@mui/icons-material/Done';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {Preloader} from "./modules/preloader";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import {useNavigate} from "react-router-dom";
import {fetchLogin} from "../api/user";
import {fetchAddTemplate} from "../api/templates";
import {PopUp} from "./modules/pop_up";
import {IsInputsNotEmpty} from "./validation/is_inputs_not_empty";
import {useElementSize} from "usehooks-ts";


function EditTemplate() {
    const defaultHelperTextNameTemplate = 'Введите название шаблона. Например: "Для работы"'
    const defaultHelperTextTextTemplate = 'Введите текст сообщения. Например: "Привет, хотел вам предложить кофеварку"'
    const navigate = useNavigate()
    const [templatesMsg, getTemplatesMsg] = useState([]);
    // Для прелоудера
    const [loading, setLoading] = useState(true);
    // Pop-up окно
    const [popUpVisible, setPopUpVisible] = useState(false);
    const [confirmPopUpVisible, setConfirmPopUpVisible] = useState(false);
    const [jwt, setJwt] = useState('');
    const [values, setValues] = useState({});
    const [isSelectedDefault, setIsSelectedDefault] = useState(true);
    const [error, setError] = useState("");
    const [helperTextNameTemplate, SetHelperTextNameTemplate] = useState(defaultHelperTextNameTemplate);
    const [helperTextTextTemplate, SetHelperTextTextTemplate] = useState(defaultHelperTextTextTemplate);
    const [NameTemplateIsEmpty, SetNameTemplateIsEmpty] = useState(false);
    const [TextTemplateNameIsEmpty, SetTextTemplateNameIsEmpty] = useState(false);
    const popUpOpen = () => {
        setPopUpVisible(true);
    };
    const popUpClose = () => {
        setPopUpVisible(false);
    };
    const handleConfirmPopUpOpen = (id) => {
        setConfirmPopUpVisible(true)
    };
    const handleConfirmPopUpClose = () => {
        setConfirmPopUpVisible(false)
    };

    const handleSwitchIsSelectedDefault = (event) => {
        console.log(event.target)
        if (event.target.checked) {
            setValues({...values, is_default: true});
            setIsSelectedDefault(true)
        } else {
            setValues({...values, is_default: false});
            setIsSelectedDefault(false)
        }
    };

    // Title страницы
    useEffect(() => {
        document.title = 'Добавить шаблон сообщения для отправки в WhatsApp - chat-starter.com'  // Title страницы
        setValues({ is_default: true });
    }, [])

    // Есть ли токен или нет?
    useEffect(() => {
        const token = localStorage.getItem("jwt_access")
        if (token) {
            setJwt('Bearer ' + token)
        } else {
            navigate('/login')
        }
    }, [])

    const SetValuesOnChange = event => {
        setValues({...values, [event.target.id]: event.target.value});
        if (event.keyCode === 13) {
            SendForm(values)
        }
    };

    const SendForm = (values) => {
        if (IsInputsNotEmpty(values, ['text_template', 'name_template'])) {
            let data = {
                text_template: values.text_template,
                name_template: values.name_template,
                is_default: values.is_default
            }
            console.log(data)
            fetchAddTemplate(jwt, data)
                .then((res) => {
                    if (res.created) {
                        navigate('/templates_messages')
                    } else if (res) {
                        console.log(res)
                        setError(res.errorMessageArray)
                        popUpOpen()
                    }
                });
        } else {
            if (!(values['name_template'])) {
                SetNameTemplateIsEmpty(true)
                SetHelperTextNameTemplate('Заполните поле')
                return false
            }
            if (!(values['text_template'])) {
                SetTextTemplateNameIsEmpty(true)
                SetHelperTextTextTemplate('Заполните поле')
                return false
            }
        }
    }

    const clearError = () => {
        SetNameTemplateIsEmpty(false)
        SetTextTemplateNameIsEmpty(false)
        SetHelperTextNameTemplate(defaultHelperTextNameTemplate)
        SetHelperTextTextTemplate(defaultHelperTextTextTemplate)
    }

    return (<>
        {loading === true ? (<>
            <h1>Новый шаблон</h1>
            <PopUp
                handleClose={popUpClose}
                open={popUpVisible}
                title={"Что-то пошло не так 😢"}
            >
                {error}
            </PopUp>
            <Box key={templatesMsg.id} sx={{
                display: 'flex', flexDirection: 'column', marginBottom: '16px'
            }}>
                <FormControl>
                    <TextField
                        onChange={SetValuesOnChange}
                        sx={{width: {xs: '100%', sm: '380px'}, marginBottom: 3}}
                        id="name_template"
                        label="Название шаблона"
                        rows={1}
                        helperText={helperTextNameTemplate}
                        error={NameTemplateIsEmpty}
                        onFocus={clearError}
                    />
                    <TextField
                        onChange={SetValuesOnChange}
                        sx={{width: {xs: '100%', sm: '380px'}}}
                        id="text_template"
                        label="Текст сообщения"
                        multiline
                        rows={8}
                        helperText={helperTextTextTemplate}
                        error={TextTemplateNameIsEmpty}
                        onFocus={clearError}
                    />
                    <FormControlLabel
                        control={<Checkbox id="is_default"
                                           value={isSelectedDefault}
                                           defaultValue={true}
                                           defaultChecked={true}
                                           onChange={handleSwitchIsSelectedDefault}/>}
                        label="Использовать по умолчанию"/>
                    <Button
                        onClick={() => {
                            SendForm(values)
                        }}
                        type={"submit"}
                        sx={{
                            marginTop: 3, marginBottom: 1, width: {xs: '100%', sm: 380}, height: 56
                        }}
                        variant="contained">Сохранить
                        <DoneIcon sx={{marginLeft: 1}}/>
                    </Button>
                    <Button
                        onClick={handleConfirmPopUpOpen}
                        variant="outlined"
                        // type={"submit"}
                        sx={{
                            marginTop: 1, marginBottom: 2, width: {xs: '100%', sm: 380}, height: 56
                        }}
                    >Отменить
                        <CancelOutlinedIcon sx={{marginLeft: 1}}/>
                    </Button>
                </FormControl>
                {/*</Box>*/}
                {/*<Box sx={{display: 'flex', flexDirection: 'column'}}>*/}

            </Box>
            {/*POP-UP*/}
            <Dialog
                open={confirmPopUpVisible}
                onClose={handleConfirmPopUpClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Вы уверены?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Все изменения будут потеряны.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmPopUpClose}>Нет</Button>
                    <Button href={'/templates_messages'} autoFocus>
                        Да
                    </Button>
                </DialogActions>
            </Dialog>
        </>) : (<Preloader/>)}
    </>);
}

export default EditTemplate;