import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DoneIcon from "@mui/icons-material/Done";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import {useNavigate} from "react-router-dom";
import {FormControl} from "@mui/material";
import {fetchUpdatePassword} from "../../api/user";
import {PopUp} from "../modules/pop_up";

function ChangePassword() {
    const navigate = useNavigate()
    const [jwt, setJwt] = useState('');
    const [values, setValues] = useState({current_password: '', new_password: ''});
    const [refresh, setRefresh] = useState(false);
    const [popUpVisible, setPopUpVisible] = useState(false);
    const [error, setError] = useState("");

    const setValuesOnChange = event => {
        setValues({...values, [event.target.id]: event.target.value});
    };
    const sendFormIfEnter = (event) => {
        if (event.keyCode === 13) {
            sendForm(jwt, values)
        }
    }

    // Валидация полей формы
    const [isValidateOldPassword, SetIsValidateOldPassword] = useState(false)
    const [isValidateNewPassword, SetIsValidateNewPassword] = useState(false)
    const [helperTextOldPassword, SetHelperTextOldPassword] = useState('')
    const [helperTextNewPassword, SetHelperTextNewPassword] = useState('')
    const closePopUpOnKeyEnter = (event) => {
        if (event.keyCode === 13) {
            setPopUpVisible(false)
        }
    }
    const popUpClose = () => {
        setPopUpVisible(false);
    };
    // Очистка error состояние у инпутов при фокусировании
    const clearError = () => {
        SetIsValidateOldPassword(false)
        SetIsValidateNewPassword(false)
        SetHelperTextOldPassword('')
        SetHelperTextNewPassword('')
    }

    const sendForm = (jwt, values) => {
        if (jwt) {
            fetchUpdatePassword(jwt, values)
                .then((response) => {
                    if (response.passwordEditedSuccess) {
                        navigate('/change_password_done')
                    } else if (response.connectionError) {
                        console.log("Проблемы с сервером")
                        setError(response.connectionError)
                        setPopUpVisible(true)
                    } else {
                        if (response.errorMessageArray.current_password) {
                            SetIsValidateOldPassword(true)
                            SetHelperTextOldPassword(response.errorMessageArray.current_password)
                        }
                        if (response.errorMessageArray.new_password) {
                            SetIsValidateNewPassword(true)
                            SetHelperTextNewPassword(response.errorMessageArray.new_password)
                        }
                    }
                })
        }
    }
    // Pop-up окно
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true)
    };
    const handleClose = () => {
        setOpen(false)
    };

    // Есть ли токен если нет - перенаправляем на "вход"?
    useEffect(() => {
        document.title = 'Изменения пароля - chat-starter.com'  // Title страницы
        // Есть ли токен или нет?
        const token = localStorage.getItem("jwt_access")
        if (token) {
            setJwt('Bearer ' + token)
        } else {
            navigate('/login')
        }
    }, [])


    return (<>
        <PopUp
            handleClose={popUpClose}
            open={popUpVisible}
            title={"Что-то пошло не так 😢"}
            closePopUpOnEnterOrEscape={closePopUpOnKeyEnter}
        >
            {error}
        </PopUp>
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
        }}>
            <h1 style={{marginBottom: '0px'}}>Изменение пароля</h1>
            <p style={{marginBottom: '24px'}}>Что бы изменить пароль - введите текущий и установите новый.</p>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
            }}>
                <FormControl>
                    <TextField
                        onKeyUp={sendFormIfEnter}
                        onChange={setValuesOnChange}
                        onFocus={clearError}
                        helperText={helperTextOldPassword}
                        error={isValidateOldPassword}
                        required
                        type='password'
                        id="current_password"
                        label="Старый пароль"
                        sx={{
                            marginBottom: 2,
                            width: {xs: '100%', sm: 380}
                        }}
                    />
                    <TextField
                        onKeyUp={sendFormIfEnter}
                        onChange={setValuesOnChange}
                        onFocus={clearError}
                        helperText={helperTextNewPassword}
                        error={isValidateNewPassword}
                        required
                        type='password'
                        id="new_password"
                        label="Новый пароль"
                        sx={{
                            marginBottom: 2,
                            width: {xs: '100%', sm: 380}
                        }}
                    />
                    <Button
                        onClick={() => {
                            sendForm(jwt, values)
                        }}
                        type={"submit"}
                        variant="contained"
                        sx={{
                            marginTop: 1,
                            marginBottom: 1,
                            height: 56,
                            width: {xs: '100%', sm: 380}
                        }}
                    >
                        Сохранить
                        <DoneIcon sx={{marginLeft: 1}}/>
                    </Button>
                    <Button
                        onClick={handleClickOpen}
                        variant="outlined"
                        // type={"submit"}
                        sx={{
                            marginTop: 1,
                            marginBottom: 2,
                            height: 56,
                            width: {xs: '100%', sm: 380}
                        }}
                    >Отменить
                        <CancelOutlinedIcon sx={{marginLeft: 1}}/>
                    </Button>
                </FormControl>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Вы уверены?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Все изменения будут потеряны.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Нет</Button>
                        <Button href={"/profile"} autoFocus>
                            Да
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    </>);
}

export default ChangePassword;
