import {Route, Routes} from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Container from '@mui/material/Container';

import ResponsiveAppBar from "./components/header";
import TemplatesMessages from "./components/templates";
import SignIn from "./components/auth/sign_in";
import Profile from "./components/auth/profile";
import Main from "./components/main";
import Login from "./components/auth/login";
import RemindPassword from "./components/auth/remind_password";
import Thanks from "./components/auth/thanks";
import Footer from "./components/footer";
import TemplateEdit from "./components/edit_template";
import AddTemplate from "./components/add_template";
import ChangePassword from "./components/auth/change_password";
import ActivationDone from "./components/auth/activation_done";
import ActivationAlreadyActivated from "./components/auth/activation_already_activated";
import ActivationWrong from "./components/auth/activation_wrong";
import Activate from "./components/auth/activate";
import Logout from "./components/auth/logout";
import UserProvider from "./components/auth/userContext";
import ChangePasswordDone from "./components/auth/change_password_done";
import RemindPasswordEmailHaveSend from "./components/auth/remind_password_email_have_send";
import ResetPassword from "./components/auth/reset_password";
import PasswordResetDone from "./components/auth/password_reset_done";
import ResetEmail from "./components/auth/reset_email";
import ChangeEmail from "./components/auth/change_email";
import ActivateNewEmail from "./components/auth/activate_new_email";
import ActivationNewEmailDone from "./components/auth/activation_new_email_done";
import InfoChangeEmailConfirm from "./components/auth/info_change_email_confirm";



const theme = createTheme({
    palette: {
        primary: {
            light: '#5f9787',
            main: '#219653',
            dark: '#26584a',
            contrastText: '#ffffff',
        },
        secondary: {
            light: '#ffa733',
            main: '#ff9100',
            dark: '#b26500',
            contrastText: '#353A3F',
        },
    },
    typography: {
        button: { // Here is where you can customise the button
            fontSize: 14,
            fontWeight: 700,
        },
        h1: {
            fontFamily: "Jost",
            color: '#0080fc'
        }
    }
});


export default function App() {
    return (
        <>
            <UserProvider>
                <ThemeProvider theme={theme}>
                    <CssBaseline/>
                    <ResponsiveAppBar/>
                    <Container sx={{
                        width: {xs: '100vw', sm: '380px'},
                        marginBottom: {xs: '16px', sm: '86px'},
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        padding: '16px'
                    }}>
                        <div style={{
                            width: 'calc(   100vw - 32px)',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                        >
                            <Routes>
                                <Route path="/" element={<Main/>}/>
                                <Route path="/logout" element={<Logout/>}/> //
                                <Route path="/login" element={<Login/>}/> //✅
                                <Route path="/change_password" element={<ChangePassword/>}/> //✅
                                <Route path="/profile" element={<Profile/>}/> //✅
                                <Route path="/sign_in" element={<SignIn/>}/> //🚨
                                <Route path="/activation_done" element={<ActivationDone/>}/> //✅
                                <Route path="/activation_already_activated" element={<ActivationAlreadyActivated/>}/> //✅
                                <Route path="/activation_wrong" element={<ActivationWrong/>}/> //✅
                                <Route path="/activate/:uid/:token" element={<Activate/>}/> //✅
                                <Route path="/activate_new_email/:uid/:token" element={<ActivateNewEmail/>}/> //✅
                                <Route path="/templates_messages" element={<TemplatesMessages/>}/> //✅
                                <Route path="/add_template" element={<AddTemplate/>}/> //✅
                                <Route path="/edit_template/:idTemplate" element={<TemplateEdit/>}/> //✅
                                <Route path="/thanks" element={<Thanks/>}/> //
                                <Route path="/activation_new_email_done" element={<ActivationNewEmailDone/>}/> //
                                <Route path="/password_reset/:uid/:token" element={<ResetPassword/>}/> //
                                <Route path="/email_reset/:uid/:token" element={<ResetEmail/>}/> //
                                <Route path="/remind_password" element={<RemindPassword/>}/> //
                                <Route path="/change_email_form" element={<ChangeEmail/>}/> //
                                <Route path="/info_change_email_confirm" element={<InfoChangeEmailConfirm/>}/> //
                                <Route path="/change_password_done" element={<ChangePasswordDone/>}/> //
                                <Route path="/password_reset_done" element={<PasswordResetDone/>}/> //
                                <Route path="/remind_password_email_have_send" element={<RemindPasswordEmailHaveSend/>}/> //
                            </Routes>
                        </div>
                        <Footer/>
                    </Container>
                </ThemeProvider>
            </UserProvider>
        </>
    );
}
