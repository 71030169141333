import {UrlPost} from "./config"
import {logDOM} from "@testing-library/react";

const url_post = UrlPost()


export function fetchLogin(data) {
    return fetch(url_post + "auth/jwt/create/", {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    })
        .then(response => {
            if (response.status === 200) {
                // Если успешный вход.
                response.json().then((data) => {
                    localStorage.setItem("jwt_access", data.access);
                    localStorage.setItem("jwt_refresh", data.refresh);
                })
                return {ok: true}
            }
            if (response.status === 401) {
                // Если не верный пароль.
                return {
                    message: ["Не верная почта или пароль.",
                        "Попробуйте еще раз или восстановите пароль."]
                }
            }
        }).catch(() => {
            return {
                message: ["Мы уже работаем над тем что бы восстановить сервис.",
                    "Попробуйте еще раз попозже."]
            }
        })
}

export function fetchSignIn(data) {
    let errorMessageArray = []
    return fetch(url_post + "auth/users/", {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    })
        .then(response => {
            if (response.status === 201) {
                return {loginSuccess: true}
            } else if (response.status === 400) {
                return response.json()
                    .then((res_data) => {
                        if (res_data.email) {
                            errorMessageArray.push("E-mail " + data.email + " уже используется.")
                        } else if (res_data.password) {
                            errorMessageArray.push("Введённый пароль слишком похож на email адрес.")
                        }
                        return {errorMessageArray: errorMessageArray}
                    })
            }
        }).catch((error) => {
            // Если бэкенд отдал - то, чего не ожидали или он не работает.
            errorMessageArray.push("Мы уже работаем над тем что бы восстановить сервис.")
            errorMessageArray.push("Попробуйте еще раз попозже.")
            return {errorMessageArray: errorMessageArray}
        });
}

export function fetchGetProfile(jwt) {
    return fetch(url_post + "users/me/", {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': jwt
        },
    })
        .then(response => {
            if (response.status === 200) {
                // Если получили данные о профиле.
                return response.json()
                    .then((data_response) => {
                        return {dataProfile: data_response}
                    })
            }
            if (response.status === 401) {
                // Если не получили данные о профиле.
                return {
                    errorMessageArray: ["Необходимо войти в аккаунт.",]
                }
            }
        }).catch(() => {
            return {
                errorMessageArray: ["Мы уже работаем над тем что бы восстановить сервис.",
                    "Попробуйте еще раз попозже."]
            }
        })
}

export function fetchEditProfile(jwt, data) {
    return fetch(url_post + "users/me/", {
        method: "PATCH",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': jwt
        },
        body: JSON.stringify(data)
    })
        .then(response => {
            if (response.status === 200) {
                // Успешное изменение данных
                console.log(response)
                return response.json()
                    .then((data_response) => {
                        return {dataProfile: data_response}
                    })
            }
            if (response.status === 401) {
                // Если не верный пароль.
                return {
                    message: ["Не верная почта или пароль.",
                        "Попробуйте еще раз или восстановите пароль."]
                }
            }
        }).catch(() => {
            return {
                connectionError: ["Мы уже работаем над тем что бы восстановить сервис.",
                    "Попробуйте еще раз попозже."]
            }
        })
}


export function fetchUpdateEmail(jwt, data) {
    return fetch(url_post + "users/set_email/", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': jwt
        },
        body: JSON.stringify(data)
    })
        .then(response => {
            if (response.status === 204) {
                // Успешное изменение Email
                console.log(response)
                return {emailEditedSuccess: true}
            }
            if (response.status === 400) {
                // Если не верный пароль, или почта не верная.
                return response.json()
                    .then((res) => {
                        let errorMessageArray = {}
                        for (var key in res) {
                            if (res[key][0] === "Invalid password.") {
                                errorMessageArray[key] = "Не верный пароль."
                            } else {
                                errorMessageArray[key] = res[key][0]
                            }
                        }
                        console.log(errorMessageArray)
                        return {errorMessageArray: errorMessageArray}
                    })
            }
        }).catch(() => {
            return {
                connectionError: ["Мы уже работаем над тем что бы восстановить сервис.",
                    "Попробуйте еще раз попозже."]
            }
        })
}


export function fetchUpdatePassword(jwt, data) {
    return fetch(url_post + "users/set_password/", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': jwt
        },
        body: JSON.stringify(data)
    })
        .then(response => {
            if (response.status === 204) {
                // Успешное изменение пароля
                console.log("Успешное изменение пароля на сервере")
                return {passwordEditedSuccess: true}
            }
            if (response.status === 400) {
                // Если не верный пароль, или почта не верная.
                return response.json()
                    .then((res) => {
                        let errorMessageArray = {}
                        for (var key in res) {
                            if (res[key][0] === "Invalid password.") {
                                errorMessageArray[key] = "Не верный пароль."
                            } else {
                                errorMessageArray[key] = res[key][0]
                            }
                        }
                        // console.log(errorMessageArray)
                        return {errorMessageArray: errorMessageArray}
                    })
            }
        }).catch(() => {
            return {
                connectionError: ["Мы уже работаем над тем что бы восстановить сервис.",
                    "Попробуйте еще раз попозже."]
            }
        })
}


export function fetchResetPassword(data) {
    return fetch(url_post + "auth/users/reset_password/", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
        .then(response => {
            if (response.status === 204) {
                // Успешное отправка письма со ссылкой для изменения пароля
                return {haveSendEmailForConfirmPassword: true}
            }
            if (response.status === 400) {
                // Почта не привязана ни к какому аккаунту
                return {wrongEmail: true}
            }
        }).catch(() => {
            return {
                connectionError: ["Мы уже работаем над тем что бы восстановить сервис.",
                    "Попробуйте еще раз попозже."]
            }
        })
}


export function fetchResetPasswordConfirm(data) {
    return fetch(url_post + "users/reset_password_confirm/", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
        .then(response => {
            if (response.status === 204) {
                // Успешное изменение пароля
                return {passwordChangedSuccess: true}
            }
            if (response.status === 400) {
                // Что-то пошло не так: Не верный uid, token
                return response.json()
                    .then((res) => {
                        if (res.new_password){
                            return {badPassword: res.new_password}
                        }
                        return {wrongData: true}
                    })
            }
        }).catch(() => {
            return {
                connectionError: ["Мы уже работаем над тем что бы восстановить сервис.",
                    "Попробуйте еще раз попозже."]
            }
        })
}


export function fetchResetEmail(jwt) {
    return fetch(url_post + "auth/users/set_new_email/", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': jwt
        },
    })
        .then(response => {
            if (response.status === 204) {
                // Успешное отправка письма со ссылкой для изменения email
                return {haveSendEmailForConfirmEmail: true}
            }
            if (response.status === 400) {
                // Почта не привязана ни к какому аккаунту
                return {wrongEmail: true}
            }
        }).catch(() => {
            return {
                connectionError: ["Мы уже работаем над тем что бы восстановить сервис.",
                    "Попробуйте еще раз попозже."]
            }
        })
}


export function fetchActivationNewEmail(data) {
    return fetch(url_post + "auth/users/activation_new_email/", {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    })
        .then(response => {
            if (response.status === 204) {
                // Успешное изменение почты
                return {emailChangedSuccess: true}
            }
            if (response.status === 400) {
                // Что-то пошло не так: Не верный uid, token или почта занята
                return response.json()
                    .then((res) => {
                        if (res.new_email){
                            return {badEmail: res.new_email}
                        }
                        return {wrongUidOrToken: true}
                    })
            }
        }).catch(() => {
            return {
                connectionError: ["Мы уже работаем над тем что бы восстановить сервис.",
                    "Попробуйте еще раз попозже."]
            }
        })
}

export function fetchSendConfirmNewEmail(jwt, data) {
    return fetch(url_post + "auth/users/send_confirm_new_email/", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': jwt
        },
        body: JSON.stringify(data)
    })
        .then(response => {
            if (response.status === 204) {
                // Успешное отправка письма со ссылкой для изменения email
                return {emailSendSuccess: true}
            }
            if (response.status === 400) {
                // Что-то пошло не так: Не верный uid, token или почта занята
                return response.json()
                    .then((res) => {
                        if (res.new_email){
                            return {badEmail: res.new_email}
                        }
                        return {wrongUidOrToken: true}
                    })
            }
        }).catch(() => {
            return {
                connectionError: ["Мы уже работаем над тем что бы восстановить сервис.",
                    "Попробуйте еще раз попозже."]
            }
        })
}