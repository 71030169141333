const host_name = process.env.REACT_APP_HOST;
const url_post = host_name + '/api/v1/'

export function fetchGetAllTemplates(jwt) {
    if (jwt) {
        return fetch(url_post + "templates/", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwt
            },
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json()
                        .then((data_response) => {
                            return {templates: data_response}
                        })
                }
            })
            .catch(() => {
                return {
                    errorMessageArray: ["Мы уже работаем над тем что бы восстановить сервис.",
                        "Попробуйте еще раз попозже."]
                }
            })
    }
}

export function fetchGetOneTemplate(jwt, idTemplate) {
    return fetch(url_post + "templates/" + idTemplate, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': jwt
        },
    })
        .then(response => {
            if (response.status === 200) {
                // Шаблон получен
                return response.json()
                    .then((data_response) => {
                        return {template: data_response}
                    })
            }
            if (response.status === 404) {
                // Шаблон не получен
                return {
                    errorMessageArray: ["Шаблон не найден."]
                }
            }
        })
        .catch(() => {
            return {
                errorMessageArray: ["Мы уже работаем над тем что бы восстановить сервис.",
                    "Попробуйте еще раз попозже."]
            }
        })
}

export function fetchAddTemplate(jwt, data) {
    if (jwt) {
        return fetch(url_post + "templates/", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwt
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.status === 201) {
                    // Шаблон успешно создан
                    return {created: true}
                } else if (response.status === 400) {
                    // Шаблон НЕ создан (Не заполнено какое-то поле)
                    return response.json().then((data_error) => {
                        console.log(response)
                        let error_array = []
                        if (data_error.name_template) {
                            error_array.push("Название шаблона не может быть пустым.")
                        }
                        if (data_error.text_template) {
                            error_array.push("Текст шаблона не может быть пустым.")
                        }
                        return {errorMessageArray: error_array}
                    })
                }

            })
            .catch(() => {
                return {
                    errorMessageArray: ["Мы уже работаем над тем что бы восстановить сервис.",
                        "Попробуйте еще раз попозже."]
                }
            })
    }
}

export function fetchEditTemplate(jwt, idTemplate, data) {
    if (jwt) {
        return fetch(url_post + "templates/" + idTemplate + "/", {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwt
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.status === 200) {
                    // Шаблон успешно изменен
                    return response.json()
                        .then((data_response) => {
                            return {template: data_response}
                        })
                }
                if (response.status === 404) {
                    // Шаблон не изменен тк не найден
                    return {
                        errorMessageArray: ["Шаблон не найден."]
                    }
                }
            })
            .catch(() => {
                return {
                    errorMessageArray: ["Мы уже работаем над тем что бы восстановить сервис.",
                        "Попробуйте еще раз попозже."]
                }
            })
    }
}

export function fetchDeleteTemplate(jwt, idTemplate) {
    if (jwt) {
        return fetch(url_post + "templates/" + idTemplate + "/", {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwt
            },
        })
            .then(response => {
                if (response.status === 204) {
                    // Шаблон успешно удвлен
                    return {deleted: true}
                }
            })
            .catch(() => {
                return {
                    errorMessageArray: ["Мы уже работаем над тем что бы восстановить сервис.",
                        "Попробуйте еще раз попозже."]
                }
            })
    }
}
