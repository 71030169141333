import Container from '@mui/material/Container';
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";

function Footer() {
    let year = new Date().getFullYear()
    return (
        <Container maxWidth="xxl"
                   sx={{
                       width: '100vw',
                       bottom: '0px',
                       height: '70px',
                       position: 'fixed',
                       background: '#343a40',
                       display: {xs: "none", sm: 'block'}
                   }}>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: '25px',
                height: "70px"
            }}>
                <Link sx={{
                    color: "white",
                    textDecoration: "underline",
                    textDecorationColor: "white"
                }}
                      variant="caption"
                      target="_blank"
                      href="https://t.me/LR_STUDIO">Разработка -
                    Vlad Mironov© {year}
                </Link>
            </Box>
        </Container>
    );
}

export default Footer;