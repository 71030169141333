import {useContext, useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import {UserContext} from "./auth/userContext";
import {MenuDesktop, MenuMobile} from "./header/menu";
import {LogoDesktop, LogoMobile} from "./header/logo";
import {ruRU} from "@mui/material/locale";
import SendIcon from '@mui/icons-material/Send';

const theme = createTheme({
    palette: {
        primary: {
            main: '#353A3F',
        },
        secondary: {
            main: '#29b6f6',
        },
    },ruRU
});
let button_send_message = ['Отправить сообщение', '', <SendIcon fontSize="small" sx={{marginRight: 1}} />]
let button_login_logout = ['Войти', 'sign_in', <LoginIcon fontSize="small" sx={{marginRight: 1}}/>]
let button_profile = ['Профиль', 'profile', <AssignmentIndIcon fontSize="small" sx={{marginRight: 1}}/>]
let button_template = ['Шаблоны', 'templates_messages', <InsertCommentIcon fontSize="small" sx={{marginRight: 1}}/>]

function ResponsiveAppBar() {
    const {isAuthenticated, setAuthenticated} = useContext(UserContext);

    const [menuButtons, setMenuButtons] = useState([button_login_logout])

    const [anchorElNav, setAnchorElNav] = useState(null);

    useEffect(() => {
        // const checkUserData = () => {
        const jwt = localStorage.getItem("jwt_access")
        if (jwt && !isAuthenticated) {
            setAuthenticated(true)
        }

    }, [])

    useEffect(() => {
        if (isAuthenticated) {
            button_login_logout = ['Выйти', 'logout', <LogoutIcon fontSize="small" sx={{marginRight: 1}}/>]
            setMenuButtons([button_send_message, button_template, button_profile, button_login_logout])
        } else {
            button_login_logout = ['Войти', 'sign_in', <LoginIcon fontSize="small" sx={{marginRight: 1}}/>]
            setMenuButtons([button_send_message, button_login_logout])
        }
    }, [isAuthenticated]);


    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <ThemeProvider theme={theme}>
            <AppBar position="static">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <LogoDesktop/>
                        <MenuDesktop
                            handleOpen={handleOpenNavMenu}
                            anchor={anchorElNav}
                            handleClose={handleCloseNavMenu}
                            data={menuButtons}
                        />
                        <LogoMobile/>
                        <MenuMobile handleClose={handleCloseNavMenu} data={menuButtons}/>
                    </Toolbar>
                </Container>
            </AppBar>
        </ThemeProvider>
    );
}

export default ResponsiveAppBar;