import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SendIcon from '@mui/icons-material/Send';
import {useNavigate} from "react-router-dom";
import {FormControl} from "@mui/material";
import {fetchGetProfile, fetchResetEmail} from "../../api/user";
import {PopUp} from "../modules/pop_up";
import {validateEmail} from "../validation/validate_email";

function ChangeEmail() {
    const navigate = useNavigate()
    const [jwt, setJwt] = useState('');
    const [values, setValues] = useState({current_password: '', new_password: ''});
    const [popUpVisible, setPopUpVisible] = useState(false);
    const [error, setError] = useState();
    const [profileData, setProfileData] = useState({username: '', first_name: '', id: 0, email: ''});

    const setValuesOnChange = event => {
        setValues({...values, [event.target.id]: event.target.value});
    };
    const sendFormIfEnter = (event) => {
        if (event.keyCode === 13) {
            sendForm(jwt, values)
        }
    }

    // Валидация полей формы
    const [isValidateOldPassword, SetIsValidateOldPassword] = useState(false)
    const [helperTextOldPassword, SetHelperTextOldPassword] = useState('')
    const closePopUpOnKeyEnter = (event) => {
        if (event.keyCode === 13) {
            setPopUpVisible(false)
        }
    }
    const popUpClose = () => {
        setPopUpVisible(false);
    };

    // Очистка error состояние у инпутов при фокусировании
    const clearError = () => {
        SetIsValidateOldPassword(false)
        SetHelperTextOldPassword('')
    }

    const sendForm = (values) => {
        if (validateEmail(values.email)) {
            fetchResetEmail(values)
                .then((response) => {
                    if (response.haveSendEmailForConfirmEmail) {
                        navigate('/info_change_email_confirm')
                    } else if (response.connectionError) {
                        console.log("Проблемы с сервером")
                        setError(response.connectionError)
                        setPopUpVisible(true)
                    } else if (response.wrongEmail) {
                        console.log("Почта не верная")
                        SetHelperTextOldPassword("Почта не верная.")
                        SetIsValidateOldPassword(true)
                    }
                })
        } else {
            SetHelperTextOldPassword("Не коррректный адрес почты.")
            SetIsValidateOldPassword(true)
        }

    }
    // Pop-up окно
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true)
    };
    const handleClose = () => {
        setOpen(false)
    };

    useEffect(() => {
        document.title = 'Изменения Email - chat-starter.com'  // Title страницы
        // Есть ли токен или нет?
        const token = localStorage.getItem("jwt_access")
        if (token) {
            setJwt('Bearer ' + token)
        } else {
            navigate('/login')
        }

    }, [])

    useEffect(() => {
        if (jwt) {
            fetchGetProfile(jwt)
                .then((response) => {
                    if (response.dataProfile) {
                        setProfileData(response.dataProfile)
                    } else {
                        console.log(response.errorMessageArray)
                        setError(response.errorMessageArray)
                        setPopUpVisible(true)
                    }
                })
        }
    }, [jwt])


    return (<>
        <PopUp
            handleClose={popUpClose}
            open={popUpVisible}
            title={"Что-то пошло не так 😢"}
            closePopUpOnEnterOrEscape={closePopUpOnKeyEnter}
        >
            {error}
        </PopUp>
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
        }}>
            <h1 style={{marginBottom: '0px'}}>Изменение почты</h1>
            <p style={{marginBottom: '24px'}}>Нажмите отправить письмо и мы отправим вам ссылку для изменения email.</p>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
            }}>
                <FormControl>
                    {/*<TextField*/}
                    {/*    onKeyUp={sendFormIfEnter}*/}
                    {/*    onChange={setValuesOnChange}*/}
                    {/*    onFocus={clearError}*/}
                    {/*    helperText={helperTextOldPassword}*/}
                    {/*    error={isValidateOldPassword}*/}
                    {/*    required*/}
                    {/*    type='email'*/}
                    {/*    id="email"*/}
                    {/*    label="Ваш текущий Email адрес"*/}
                    {/*    sx={{*/}
                    {/*        marginBottom: 2,*/}
                    {/*        width: {xs: '100%', sm: 380}*/}
                    {/*    }}*/}
                    {/*/>*/}
                    <Button
                        onClick={() => {
                            sendForm({email: profileData.email})
                        }}
                        type={"submit"}
                        variant="contained"
                        sx={{
                            marginTop: 1,
                            marginBottom: 1,
                            height: 56,
                            width: {xs: '100%', sm: 380}
                        }}
                    >
                        Отправить письмо
                        <SendIcon sx={{marginLeft: 1}}/>
                    </Button>
                    <Button
                        onClick={handleClickOpen}
                        href={'/profile'}
                        variant="outlined"
                        sx={{
                            marginTop: 1,
                            marginBottom: 2,
                            height: 56,
                            width: {xs: '100%', sm: 380}
                        }}
                    >Назад
                        <ArrowBackIcon sx={{marginLeft: 1}}/>
                    </Button>
                </FormControl>
            </Box>
        </Box>
    </>);
}

export default ChangeEmail;
