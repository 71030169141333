import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import {useNavigate} from "react-router-dom";
import {FormControl, InputAdornment} from "@mui/material";
import {fetchEditProfile, fetchGetProfile, fetchResetEmail, fetchUpdateEmail} from "../../api/user";
import IconButton from "@mui/material/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import {logDOM} from "@testing-library/react";
import {validateName} from "../validation/validate_name";
import {PopUp} from "../modules/pop_up";
import KeyIcon from '@mui/icons-material/Key';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PasswordIcon from '@mui/icons-material/Password';
import PinIcon from '@mui/icons-material/Pin';
import {validateEmail} from "../validation/validate_email";

function Profile(props) {
    // Переадресация
    const navigate = useNavigate()
    const [values, setValues] = useState({});
    const [isValidateEmail, SetValidateEmail] = useState(false)
    const [isValidateName, SetValidateName] = useState(false)
    const [helperTextEmail, SetHelperTextEmail] = useState('')
    const [helperTextName, SetHelperTextName] = useState('')
    const [refresh, setRefresh] = useState(false);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState("");
    const [popUpVisible, setPopUpVisible] = useState(false);
    const [isCloseChangingNamePopUp, setIsCloseChangingNamePopUp] = useState(false);
    const [isCloseChangingEmailPopUp, setIsCloseChangingEmailPopUp] = useState(false);
    const [jwt, setJwt] = useState('');
    const [profileData, setProfileData] = useState({username: '', first_name: '', id: 0, email: ''});
    const [isErrorEmailValue, setIsErrorEmailValue] = useState(false);
    const [isErrorPasswordValue, setIsErrorPasswordValue] = useState(false);
    const [helperTextEmailValue, setHelperTextEmailValue] = useState('');
    const [helperTextPasswordValue, setHelperTextPasswordValue] = useState('');

    const handleClickOpen = () => {
        // setOpen(true)
    };
    const closePopUpOnKeyEnter = (event) => {
        if (event.keyCode === 13) {
            setPopUpVisible(false)
        }
    }
    const popUpClose = () => {
        setPopUpVisible(false);
    };
    const handleClose = () => {
        // setIsCloseChangingNamePopUp(false)
    };

    const handleCloseChangingNamePopUp = () => {
        setIsCloseChangingNamePopUp(false)
    };

    const handleOpenChangingNamePopUp = () => {
        setValues({})
        setIsCloseChangingNamePopUp(true)
    };

    const handleCloseChangingEmailPopUp = () => {
        clearErrorChangeEmail()
        setIsCloseChangingEmailPopUp(false)
    };

    const handleOpenChangingEmailPopUp = () => {
        clearErrorChangeEmail()
        setValues({})
        setIsCloseChangingEmailPopUp(true)
    };

    const clearErrorChangeEmail = () => {
        setIsErrorEmailValue(false)
        setIsErrorPasswordValue(false)
        setHelperTextEmailValue('')
        setHelperTextPasswordValue('')
    }

    const clearErrorChangeName = () => {
        SetValidateName(false)
        SetHelperTextName('')
    }

    const setValuesOnChange = (event) => {
        setValues({...values, [event.target.id]: event.target.value});
    };

    const sendFormChangeNameIfEnter = (event) => {
        if (event.keyCode === 13) {
            sendFormChangeName(jwt, values)
        }
    }


    const sendFormChangeName = (jwt, values) => {
        // Заполене ли объект values:
        let isValueNotEmpty = Object.keys(values).length
        if (isValueNotEmpty) {
            const name = values.first_name
            if (validateName(name)) {
                fetchEditProfile(jwt, values)
                    .then((res) => {
                        if (res.connectionError) {
                            console.log("Проблема с подключением к серверу")
                            setError(res.connectionError)
                            setIsCloseChangingNamePopUp(false)
                            setPopUpVisible(true)
                        } else if (res.dataProfile) {
                            handleCloseChangingNamePopUp()
                            setRefresh(true)
                        }
                    })
            } else {
                SetValidateName(true)
                SetHelperTextName('Имя может содержать только буквы.')
            }
        } else {
            SetValidateName(true)
            SetHelperTextName('Имя не может быть пустым.')
        }
    }


    const sendFormChangeEmail = (values) => {
        if (validateEmail(values.email)) {
            fetchResetEmail(jwt)
                .then((response) => {
                    if (response.haveSendEmailForConfirmEmail) {
                        navigate('/info_change_email_confirm')
                    } else if (response.connectionError) {
                        console.log("Проблемы с сервером")
                        setError(response.connectionError)
                        setPopUpVisible(true)
                    } else if (response.wrongEmail) {
                        console.log("Почта не верная")
                    }
                })
        } else {
            console.log("Почта не валидная")
        }

    }

    // Есть ли токен если нет - перенаправляем на "вход"?
    useEffect(() => {
        document.title = 'Профиль - chat-starter.com'  // Title страницы
        // Есть ли токен или нет?
        const token = localStorage.getItem("jwt_access")
        if (token) {
            setJwt('Bearer ' + token)
        } else {
            navigate('/login')
        }
    }, [])

    // [GET] - Получение информации о профиле
    useEffect(() => {
        if (jwt) {
            fetchGetProfile(jwt)
                .then((response) => {
                    if (response.dataProfile) {
                        setProfileData(response.dataProfile)
                    } else {
                        console.log(response.errorMessageArray)
                        setError(response.errorMessageArray)
                        setPopUpVisible(true)
                    }
                })
            setRefresh(false)
        }
    }, [jwt, refresh])


    return (<>
            <PopUp
                handleClose={popUpClose}
                open={popUpVisible}
                title={"Что-то пошло не так 😢"}
                closePopUpOnEnterOrEscape={closePopUpOnKeyEnter}
            >
                {error}
            </PopUp>
            <h1 style={{marginBottom: '0px'}}>Профиль</h1>
            <p style={{marginBottom: '24px'}}>Информация о пользователе.</p>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
            }}>
                <FormControl>
                    <TextField
                        disabled
                        id="first_name"
                        label="Имя"
                        value={profileData.first_name}
                        sx={{
                            width: {xs: '100%', sm: 380},
                            marginBottom: 2
                        }}
                        InputProps={{
                            style: {paddingRight: 0},
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        sx={{
                                            height: '56px',
                                            width: '56px',
                                            paddingRight: "0px"
                                        }}
                                        id="first_name"
                                        color="primary"
                                        onClick={handleOpenChangingNamePopUp}
                                    >
                                        <EditIcon fontSize="small"/>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button
                        onClick={() => {
                            sendFormChangeEmail({email: profileData.email})
                        }}
                        variant="outlined"
                        sx={{
                            marginBottom: 1,
                            width: {xs: '100%', sm: 380},
                            height: 56
                        }}
                    >Изменить почту
                        <AlternateEmailIcon sx={{marginLeft: 1}} size="small"/>
                    </Button>
                    <Button
                        href={"/change_password"}
                        variant="outlined"
                        sx={{
                            marginBottom: 1,
                            width: {xs: '100%', sm: 380},
                            height: 56
                        }}
                    >Изменить пароль
                        <LockOpenIcon sx={{marginLeft: 1}} size="small"/>
                    </Button>

                    {/*[ POP-UP] Изменения имени*/}
                    <Dialog
                        open={isCloseChangingNamePopUp}
                        onClose={handleCloseChangingNamePopUp}
                        fullWidth
                    >
                        <DialogTitle>Изменение имени</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Введите имя. Имя может состоять только из буквы.
                            </DialogContentText>
                            <TextField
                                onChange={setValuesOnChange}
                                onFocus={clearErrorChangeName}
                                onKeyUp={sendFormChangeNameIfEnter}
                                helperText={helperTextName}
                                error={isValidateName}
                                required
                                margin="dense"
                                id="first_name"
                                name="name"
                                label="Имя"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseChangingNamePopUp}>Отмена</Button>
                            <Button
                                type="submit"
                                onClick={() => {
                                    sendFormChangeName(jwt, values)
                                }}
                            >Сохранить
                            </Button>
                        </DialogActions>
                    </Dialog>
                </FormControl>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Вы уверены?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Все изменения будут потеряны.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Нет</Button>
                        <Button href={'/'} autoFocus>
                            Да
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
            <Box sx={{
                display: 'flex',
                padding: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 2,
                width: {xs: '100%', sm: 380},
                height: 56
            }}>
            </Box>
        </>
    )
        ;
}

export default Profile;

