import banner_iphone from '../media/image/banner/banner_iphone.png'
import Button from "@mui/material/Button";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import Typography from "@mui/material/Typography";

function Banner() {
    return (
        <>
            <div style={{position: 'relative'}}>
                <h2>Шаблоны сообщений</h2>
                <Typography variant="body2" gutterBottom
                            style={{
                                marginBottom: '24px',
                                // marginTop: '12px',
                                color: '#4c4c4c'
                            }}>
                    Регистрируйся и используй шаблоны сообщений.
                </Typography>
                <img style={{
                    width: '100%',
                    marginTop: '50px'
                }} src={banner_iphone} alt=""/>
                <Button
                    color={"secondary"}
                    href={'/sign_in'}
                    type={"submit"}
                    sx={{
                        position: 'absolute',
                        top: '150px',
                        right: '0px',
                        marginBottom: 2,
                        width: {xs: '50%', sm: 182},
                        height: 56
                    }}
                    variant="contained">РЕГИСТРАЦИЯ
                    <AssignmentIndIcon sx={{marginLeft: 1}}/>
                </Button>
            </div>
        </>
    );
}

export default Banner;