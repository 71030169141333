// Форма регистрации

//TODO Регистрация - функционал
//TODO Сделать переадресацию в том случае если от сервера ответ 200
//TODO Валидация неверно заполненных полей(логин или емейл уже существует)

import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Link from '@mui/material/Link';
import {FormControl} from "@mui/material";
import {IsInputsNotEmpty} from "../validation/is_inputs_not_empty";
import {validateEmail} from "../validation/validate_email";
import {IsLoginValid} from "../validation/validate_login"
import {isPasswordValid} from "../validation/validate_password"
import {fetchSignIn} from "../../api/user";
import {PopUp} from "../modules/pop_up";
import LoginIcon from '@mui/icons-material/Login';


function Sign_in() {
    const [popUpVisible, setPopUpVisible] = useState(false);
    const [error, setError] = useState("");
    // Валидация полей формы
    const [isValidateEmail, SetValidateEmail] = useState(false)
    const [isValidateUser, SetValidateUser] = useState(false)
    const [isValidatePassword, SetValidatePassword] = useState(false)
    const [helperTextEmail, SetHelperTextEmail] = useState('')
    const [helperTextUser, SetHelperTextUser] = useState('')
    const [helperTextPassword, SetHelperTextPassword] = useState('')
    const popUpOpen = () => {
        setPopUpVisible(true);
    };
    const popUpClose = () => {
        setPopUpVisible(false);
    };

    // Title страницы
    useEffect(() => {
        document.title = 'Регистрация нового пользователя - chat-starter.com'  // Title страницы
    })

    // Переадресация
    const navigate = useNavigate()

    // Получение значений из полей формы
    const [values, setValues] = useState('');
    const SetValuesOnChange = event => {
        setValues({...values, [event.target.id]: event.target.value});
        if (event.keyCode === 13) {
            SendForm(values)
        }
    };


    // Очистка error состояние у инпутов при фокусировании
    const clearError = () => {
        SetValidateEmail(false)
        SetValidatePassword(false)
        SetValidateUser(false)
        SetHelperTextEmail('')
        SetHelperTextPassword('')
        SetHelperTextUser('')
    }

    // Отправка формы (проверяем заполненность и валидность полей)
    const CheckForm = (values) => {
        if (IsInputsNotEmpty(values, ['email', 'password', 'first_name'])) {
            // console.log('Поля формы заполнены')
            if (validateEmail(values['email'])) {
                if (isPasswordValid(values['password'])) {
                    return true
                } else {
                    SetValidatePassword(true)
                    SetHelperTextPassword('Мин. длинна пароля - 8 символов, пароль должен содержать буквы и цифры.')
                    return false
                }
            } else {
                SetValidateEmail(true)
                SetHelperTextEmail('Не корректный e-mail')
                return false
            }
        } else {
            if (!(values['email'])) {
                SetValidateEmail(true)
                SetHelperTextEmail('Обязательное поле')
                return false
            }
            if (!(values['first_name'])) {
                SetValidateUser(true)
                SetHelperTextUser('Обязательное поле')
                return false
            }
            if (!(values['password'])) {
                SetValidatePassword(true)
                SetHelperTextPassword('Обязательное поле')
                return false
            }
        }
    }

    //Функция отправки данных из формы
    const SendForm = (values) => {
        let result_validation_form = CheckForm(values)
        if (result_validation_form === true) {
            let data = {
                email: values.email,
                first_name: values.first_name,
                password: values.password,
                username: values.email,
            }
            fetchSignIn(data).then((res) => {
                if (res.loginSuccess) {
                    // Если успешная регистрация.
                    navigate('/thanks')
                } else {
                    // Если пользователь email существуют, или не работает сервер
                    setError(res.errorMessageArray)
                    popUpOpen()
                }
            })
        }
    }

    const ClosePopUpOnKeyEnter = (event) => {
        if (event.keyCode === 13) {
            setPopUpVisible(false)
        }
    }

    return (<>
            <PopUp
                handleClose={popUpClose}
                open={popUpVisible}
                title={"Что-то пошло не так 😢"}
                closePopUpOnEnterOrEscape={ClosePopUpOnKeyEnter}
            >
                {error}
            </PopUp>
            <Box sx={{
                display: 'flex', justifyContent: 'center', flexDirection: 'column',
            }}>
                <h1 style={{marginBottom: '0px'}}>Регистрация</h1>
                <p style={{marginBottom: '24px'}}> Чтобы пользоваться шаблонами для отправки
                    сообщений войдите или
                    зарегистрируйтесь.</p>
                <FormControl>
                    <TextField
                        onKeyUp={SetValuesOnChange}
                        helperText={helperTextEmail}
                        error={isValidateEmail}
                        onFocus={clearError}
                        margin={"dense"}
                        sx={{
                            marginBottom: 1, width: {xs: '100%', sm: 380}
                        }}
                        required
                        id="email"
                        label="E-mail"
                        defaultValue=""
                    />
                    <TextField
                        onKeyUp={SetValuesOnChange}
                        helperText={helperTextUser}
                        error={isValidateUser}
                        onFocus={clearError}
                        margin={"dense"}
                        sx={{
                            marginBottom: 1, width: {xs: '100%', sm: 380}
                        }}
                        required
                        id="first_name"
                        label="Имя"
                        defaultValue=""
                    />
                    <TextField
                        onKeyUp={SetValuesOnChange}
                        helperText={helperTextPassword}
                        error={isValidatePassword}
                        onFocus={clearError}
                        margin={"dense"}
                        sx={{
                            marginBottom: 3, width: {xs: '100%', sm: 380}
                        }}
                        required
                        type="password"
                        id="password"
                        label="Пароль"
                        defaultValue=""
                    />
                    <Button
                        onClick={() => {
                            SendForm(values)
                        }}
                        type={"submit"}
                        sx={{
                            marginBottom: 1, width: {xs: '100%', sm: 380}, height: 56
                        }}
                        variant="contained">РЕГИСТРАЦИЯ
                    </Button>
                </FormControl>
            </Box>
            <Box sx={{
                display: 'flex',
                padding: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 2,
                width: {xs: '100%', sm: 380},
                height: 56
            }}>

                <Link underline="none" href="/login">Вход
                </Link>

                <div>
                    <Link underline="none" href="/remind_password">Забыли
                        пароль?</Link>

                </div>
            </Box>

        </>
    )
}

export default Sign_in;

