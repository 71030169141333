import {useEffect} from 'react';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";

function ChangePasswordDone() {
    const navigate = useNavigate()

    useEffect(() => {
        document.title = 'Пароль успешно изменен - chat-starter.com'
        const token = localStorage.getItem("jwt_access")
        if (!token) {
            navigate('/')
        }
    }, [])

    return (<>
        <Box sx={{
            display: 'flex', justifyContent: 'center', flexDirection: 'column',
        }}>
            <h2 style={{marginBottom: '0px'}}>✅ Пароль успешно изменен.</h2>
            <p style={{marginBottom: '24px'}}>
                Что бы продолжить пользоваться сервисом - перейдите на главную страницу.
            </p>
            <Box sx={{
                display: 'flex', justifyContent: 'center', flexDirection: 'column',
            }}>
                <Box sx={{
                    display: 'flex', justifyContent: 'center', flexDirection: 'column',
                }}>
                    <Button
                        href={'/'}
                        variant="contained"
                        sx={{
                            marginBottom: 1,
                            width: {xs: '100%', sm: 380},
                            height: 56
                        }}
                    >
                        На главную
                    </Button>
                </Box>
            </Box>
        </Box>
    </>)
}

export default ChangePasswordDone;

