import React from 'react';
import ReactDOM from "react-dom/client";

import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import {BrowserRouter as Router} from "react-router-dom"

const root = ReactDOM.createRoot(document.getElementById("root"));



root.render(
    <React.StrictMode>
        <Router>
            <App/>
        </Router>
    </React.StrictMode>
)

// reportWebVitals();

//TODO Решить вопрос с reportWebVitals (что за вопросы? - не помню, разобраться)
//TODO React.StrictMode - вызывает запросы дважды, отключить при продакшене


