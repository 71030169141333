import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import {FormControl} from "@mui/material";
import {IsInputsNotEmpty} from "../validation/is_inputs_not_empty";
import {validateEmail} from "../validation/validate_email";
import {useNavigate} from "react-router-dom";
import {PopUp} from "../modules/pop_up";
import {logDOM} from "@testing-library/react";
import {fetchResetPassword} from "../../api/user";

function RemindPassword() {
    const navigate = useNavigate()
    const [jwt, setJwt] = useState('');
    const [values, setValues] = useState({email: ""});
    const [isValidateEmail, SetIsValidateEmail] = useState(false)
    const [helperTextEmail, SetHelperTextEmail] = useState('')
    const [popUpVisible, setPopUpVisible] = useState(false);
    const [error, setError] = useState("");
    const popUpClose = () => {
        setPopUpVisible(false);
    };

    const closePopUpOnKeyEnter = (event) => {
        if (event.keyCode === 13) {
            setPopUpVisible(false)
        }
    }

    const SetValuesOnChange = event => {
        setValues({...values, [event.target.id]: event.target.value});
    };

    // Очистка error состояние у инпутов при фокусировании
    const clearError = () => {
        SetIsValidateEmail(false)
        SetHelperTextEmail('')
    }

    // Отправка формы (проверяем заполненность и валидность полей)
    const SendForm = (values) => {
        if (values.email) {
            // console.log("Пароль не пустой")
            console.log(values)
            if (validateEmail(values.email)) {
                // console.log("Пароль валидный")
                fetchResetPassword(values)
                    .then((response) => {
                        console.log(response)
                        if (response.connectionError) {
                            console.log("Не работает сервер")
                            setPopUpVisible(true)
                            setError(response.connectionError)
                        } else if (response.wrongEmail) {
                            SetHelperTextEmail("Почта не привязана ни к одному аккаунта")
                            SetIsValidateEmail(true)
                        } else if (response.haveSendEmailForConfirmPassword) {
                            navigate('/remind_password_email_have_send')
                        }
                    })
            } else {
                console.log("Почта не валидная")
                SetHelperTextEmail("Почта не верная.")
                SetIsValidateEmail(true)
            }
        } else {
            console.log("Почта пустая")
            SetHelperTextEmail("Заполните почту.")
            SetIsValidateEmail(true)
        }
    }

    // Есть ли токен если нет - перенаправляем на "главную"?
    useEffect(() => {
        document.title = 'Восстановление пароля chat-starter.com'  // Title страницы
        const token = localStorage.getItem("jwt_access")
        if (token) {
            navigate('/')
        }
    }, [])

    return (<>
        <PopUp
            handleClose={popUpClose}
            open={popUpVisible}
            title={"Что-то пошло не так 😢"}
            closePopUpOnEnterOrEscape={closePopUpOnKeyEnter}
        >
            {error}
        </PopUp>
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
        }}>
            <h1 style={{marginBottom: '0px'}}>Восстановить пароль</h1>
            <p style={{marginBottom: '24px'}}>Введите E-mail и мы отправим на него ссылку для
                восстановления пароля.</p>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
            }}>
                <FormControl>
                    <TextField
                        onKeyUp={SetValuesOnChange}
                        error={isValidateEmail}
                        helperText={helperTextEmail}
                        onFocus={clearError}
                        required
                        id="email"
                        label="E-mail"
                        defaultValue=""
                        sx={{
                            marginBottom: 3,
                            width: {xs: '100%', sm: 380}
                        }}
                    />
                    <Button
                        onClick={() => {
                            SendForm(values)
                        }}
                        variant="contained"
                        sx={{
                            marginBottom: 1,
                            width: {xs: '100%', sm: 380}, height: 56
                        }}
                    >
                        Восстановить
                    </Button>
                </FormControl>
            </Box>
        </Box>
        <Box sx={{
            display: 'flex',
            padding: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 2,
            width: {xs: '100%', sm: 380},
            height: 56
        }}>
            <div>
                <Link underline="none" href="/login">Вход</Link>
            </div>
            <div>
                <Link underline="none" href="/sign_in">Регистрация</Link>
            </div>

        </Box>
    </>)
}

export default RemindPassword;

