import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import {useNavigate} from "react-router-dom";
import {FormControl} from "@mui/material";
import {fetchResetPasswordConfirm} from "../../api/user";
import {PopUp} from "../modules/pop_up";
import {useParams} from "react-router";
import DoneIcon from "@mui/icons-material/Done";
import {isPasswordValid} from "../validation/validate_password";

function ResetPassword(props) {
    // Переадресация
    const [values, setValues] = useState({
        uid: "",
        token: "",
        new_password: ""
    });
    const navigate = useNavigate()
    const [isValidatePassword, SetIsValidatePassword] = useState(false)
    const [helperTextPassword, SetHelperTextPassword] = useState('')
    const [isValidateEmail, SetValidateEmail] = useState(false)
    const [isValidateName, SetValidateName] = useState(false)
    const [helperTextEmail, SetHelperTextEmail] = useState('')
    const [helperTextName, SetHelperTextName] = useState('')
    const [refresh, setRefresh] = useState(false);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState("");
    const [popUpVisible, setPopUpVisible] = useState(false);
    const [isCloseChangingNamePopUp, setIsCloseChangingNamePopUp] = useState(false);
    const [isCloseChangingEmailPopUp, setIsCloseChangingEmailPopUp] = useState(false);
    const [jwt, setJwt] = useState('');
    const [profileData, setProfileData] = useState({username: '', first_name: '', id: 0, email: ''});
    const [isErrorEmailValue, setIsErrorEmailValue] = useState(false);
    const [isErrorPasswordValue, setIsErrorPasswordValue] = useState(false);
    const [helperTextEmailValue, setHelperTextEmailValue] = useState('');
    const [helperTextPasswordValue, setHelperTextPasswordValue] = useState('');
    const {uid, token} = useParams();  // Параметры url

    const handleClickOpen = () => {
        // setOpen(true)
    };
    const closePopUpOnKeyEnter = (event) => {
        if (event.keyCode === 13) {
            setPopUpVisible(false)
        }
    }
    const popUpClose = () => {
        setPopUpVisible(false);
    };
    const handleClose = () => {
        // setIsCloseChangingNamePopUp(false)
    };

    const handleCloseChangingNamePopUp = () => {
        setIsCloseChangingNamePopUp(false)
    };

    const handleOpenChangingNamePopUp = () => {
        setValues({})
        setIsCloseChangingNamePopUp(true)
    };

    const sendFormIfEnter = (event) => {
        if (event.keyCode === 13) {
            sendForm(values)
        }
    }

    const handleCloseChangingEmailPopUp = () => {
        clearErrorChangeEmail()
        setIsCloseChangingEmailPopUp(false)
    };

    const handleOpenChangingEmailPopUp = () => {
        clearErrorChangeEmail()
        setValues({})
        setIsCloseChangingEmailPopUp(true)
    };

    const clearErrorChangeEmail = () => {
        setIsErrorEmailValue(false)
        setIsErrorPasswordValue(false)
        setHelperTextEmailValue('')
        setHelperTextPasswordValue('')
    }

    const clearErrorChangeName = () => {
        SetValidateName(false)
        SetHelperTextName('')
    }

    const setValuesOnChange = (event) => {
        setValues({...values, [event.target.id]: event.target.value});
    };


    const sendForm = (values) => {
        if (isPasswordValid(values.new_password)) {
            fetchResetPasswordConfirm(values)
            .then((response) => {
                if (response.connectionError) {
                    console.log("Ошибка на сервере")
                    setError(response.connectionError)
                    setPopUpVisible(true)
                } else if (response.badPassword) {
                    console.log("Пароль не прошел валидацию на сервере")
                    setError(response.badPassword)
                    setPopUpVisible(true)
                } else if (response.wrongData) {
                    console.log("Отправлены не верные данные")
                    setError(["Ссылка устарела или не верная.",
                        "Попробуйте восстановить пароль заново."])
                    setPopUpVisible(true)
                } else if (response.passwordChangedSuccess) {
                    console.log("Пароль успешно изменен")
                    navigate('/password_reset_done')
                }
            })
        } else {
            SetIsValidatePassword(true)
            SetHelperTextPassword('Мин. длинна пароля - 8 символов, пароль должен содержать буквы и цифры.')
        }
    }

    // Есть ли токен перенаправляем на "вход"
    useEffect(() => {
        document.title = 'Изменение пароля - chat-starter.com'  // Title страницы
        setValues({...values, uid: uid, token: token})
        // Есть ли токен или нет?
        const jwt_token = localStorage.getItem("jwt_access")
        if (jwt_token) {
            navigate('/')
        }
    }, [])

    return (<>
            <PopUp
                handleClose={popUpClose}
                open={popUpVisible}
                title={"Что-то пошло не так 😢"}
                closePopUpOnEnterOrEscape={closePopUpOnKeyEnter}
            >
                {error}
            </PopUp>
            <h2 style={{marginBottom: '0px'}}>🔐 Установите пароль</h2>
            <p style={{marginBottom: '24px'}}>Введите новый пароль.</p>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
            }}>
                <FormControl>
                    <TextField
                        onKeyUp={sendFormIfEnter}
                        onChange={setValuesOnChange}
                        error={isValidatePassword}
                        helperText={helperTextPassword}
                        required
                        sx={{
                            width: {xs: '100%', sm: 380},
                        }}
                        id="new_password"
                        label="Пароль"
                        type="password"
                    />
                    <Button
                        onClick={() => {
                            sendForm(values)
                        }}
                        type={"submit"}
                        sx={{
                            marginTop: 3,
                            marginBottom: 1,
                            width: {xs: '100%', sm: 380},
                            height: 56
                        }}
                        variant="contained">Сохранить
                        <DoneIcon sx={{marginLeft: 1}}/>
                    </Button>
                </FormControl>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Вы уверены?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Все изменения будут потеряны.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Нет</Button>
                        <Button href={'/'} autoFocus>
                            Да
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
            <Box sx={{
                display: 'flex',
                padding: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 2,
                width: {xs: '100%', sm: 380},
                height: 56
            }}>
            </Box>
        </>
    )
        ;
}

export default ResetPassword;

