//Страница ввода телефона
import Box from "@mui/material/Box";
import InputNumber from "./modules/input_number";
import Banner from "./banner";
import Typography from "@mui/material/Typography";
import SelectTemplate from "./modules/select_template";
import React, {useEffect, useState} from "react";
import {Preloader} from "./modules/preloader";

function Main() {

    const [isAuthenticated, setIsAuthenticated] = useState(false);  // Токен

    useEffect(() => {
        document.title = 'Написать в WhatsApp без добавления контакта - chat-starter.com'  // Title страницы
        const token = localStorage.getItem("jwt_access")
        if (token) {
            setIsAuthenticated(true)
        }
    }, [])

    return (<>
        <Box sx={{
            display: 'flex', justifyContent: 'center', flexDirection: 'column',
        }}>
            <h1 style={{marginBottom: '0px'}}>Написать в WhatsApp.</h1>
            <Typography variant="body2" gutterBottom
                        style={{marginBottom: '24px', marginTop: '12px', color: '#4c4c4c'}}>Для того что бы <b>написать
                сообщение в WhatsApp, не добавляя в контакты</b> - вставьте или
                введите номер телефона. На вашем устройстве должно быть <b>установлено приложение WhatsApp.</b>
            </Typography>
            {!isAuthenticated ?
                (<>
                    <InputNumber/>
                    <Banner/>
                </>) :
                (
                    <SelectTemplate/>)
            }
        </Box>
    </>);
}

export default Main;

