import {useEffect} from 'react';
import Button from "@mui/material/Button";

function Thanks() {
    // Title страницы
    useEffect(() => {
        document.title = 'Спасибо за регистрацию - chat-starter.com'  // Title страницы
    })

    return (<div>
        <h2 style={{marginBottom: '0px'}}>📧 Проверьте почту! </h2>
        <p style={{marginBottom: '24px'}}>Мы отправили Вам письмо со
            ссылкой для активации вашего аккаунта.</p>
        {/*<Button href="/login" type={"submit"}*/}
        {/*        sx={{*/}
        {/*            marginTop: 1, marginBottom: 2, width: {xs: '100%', sm: 380}, height: 56*/}
        {/*        }}*/}
        {/*        variant="contained">Войти*/}
        {/*</Button>*/}
    </div>);
}

export default Thanks;