import {createContext, useState} from 'react';

export const UserContext = createContext();

function UserProvider({children}) {
    const [isAuthenticated, setAuthenticated] = useState(false);
    return (
        <UserContext.Provider value={{isAuthenticated, setAuthenticated}}>
            {children}
        </UserContext.Provider>
    );
};

export default UserProvider;