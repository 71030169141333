import React, {useContext, useEffect, useState} from 'react';

import {useNavigate} from 'react-router-dom';
import {useParams} from "react-router";
import {fetchActivationNewEmail} from "../../api/user";
import {PopUp} from "../modules/pop_up";
import {UserContext} from "./userContext";

function ActivateNewEmail(props) {
    const navigate = useNavigate();
    const {uid, token} = useParams();  // Параметры url
    const queryParams = new URLSearchParams(window.location.search)
    const new_email = queryParams.get("new_email")
    const [error, setError] = useState([]);
    const [popUpVisible, setPopUpVisible] = useState(false);
    const { setAuthenticated } = useContext(UserContext);

    const popUpClose = () => {
        navigate('/change_email_form')
    };

    const closePopUpOnKeyEnter = (event) => {
        if (event.keyCode === 13) {
            navigate('/change_email_form')
        }
    }

    useEffect(() => {
        document.title = 'Активация новой почты - chat-starter.com'  // Title страницы
    });


    useEffect(() => {
        let data = {
            uid: uid,
            token: token,
            new_email: new_email
        }
        fetchActivationNewEmail(data)
            .then((res) => {
                if (res.connectionError){
                    setError(res.connectionError)
                    setPopUpVisible(true)
                } else if (res.wrongUidOrToken){
                    setError(["Не корректная ссылка.", "Попробуйте изменить почту еще раз."])
                    setPopUpVisible(true)
                } else if (res.badEmail){
                    let badEmail = res.badEmail
                    badEmail.push("Попробуйте изменить почту еще раз.")
                    setError(badEmail)
                    setPopUpVisible(true)
                } else if (res.emailChangedSuccess){
                    localStorage.clear()
                    setAuthenticated(false)
                    navigate('/activation_new_email_done')
                }
            })
    }, [uid, token]);

    return (<PopUp
                handleClose={popUpClose}
                open={popUpVisible}
                title={"Что-то пошло не так 😢"}
                closePopUpOnEnterOrEscape={closePopUpOnKeyEnter}
            >
                {error}
            </PopUp>)
}

export default ActivateNewEmail;