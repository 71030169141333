// Добавление шаблона

import React, {useEffect, useState} from 'react';
import Button from "@mui/material/Button";
import {Checkbox, FormControl, FormControlLabel} from "@mui/material";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import DoneIcon from '@mui/icons-material/Done';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {Preloader} from "./modules/preloader";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import {useNavigate} from "react-router-dom";
import {fetchEditTemplate, fetchGetOneTemplate} from "../api/templates";
import {PopUp} from "./modules/pop_up";
import {IsInputsNotEmpty} from "./validation/is_inputs_not_empty";
import {useParams} from "react-router";
import Link from "@mui/material/Link";


function EditTemplate() {
    const [jwt, setJwt] = useState('');  // Токен
    const {idTemplate} = useParams();  // id шаблона из url параметра
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false); // Прелоудер
    const [popUpVisible, setPopUpVisible] = useState(false);  // Pop-up окно
    const [confirmPopUpVisible, setConfirmPopUpVisible] = useState(false);
    const [values, setValues] = useState({is_default: false, name_template: '', text_template: ''});
    const [error, setError] = useState("");
    const [helperTextNameTemplate, SetHelperTextNameTemplate] = useState('');
    const [helperTextTextTemplate, SetHelperTextTextTemplate] = useState('');
    const [NameTemplateIsEmpty, SetNameTemplateIsEmpty] = useState(false);
    const [TextTemplateNameIsEmpty, SetTextTemplateNameIsEmpty] = useState(false);
    const popUpOpen = () => {
        setPopUpVisible(true);
    };
    const popUpClose = () => {
        setPopUpVisible(false);
    };
    const handleConfirmPopUpOpen = (id) => {
        setConfirmPopUpVisible(true)
    };
    const handleConfirmPopUpClose = () => {
        setConfirmPopUpVisible(false)
    };

    const SetValuesOnChange = event => {
        if (event.target.type === "checkbox"){
            setValues({...values, is_default:event.target.checked})
        }
        else
            setValues({...values, [event.target.id]: event.target.value});
            if (event.keyCode === 13) {
                SendForm(values)
            }
    };

    const SendForm = (values) => {
        if (IsInputsNotEmpty(values, ['text_template', 'name_template',])) {
            fetchEditTemplate(jwt, idTemplate, values)
                .then((res) => {
                    if (res.template) {
                        navigate('/templates_messages')
                    } else if (res) {
                        setError(res.errorMessageArray)
                        popUpOpen()
                    }
                });
        } else {
            if (!(values['name_template'])) {
                SetNameTemplateIsEmpty(true)
                SetHelperTextNameTemplate('Заполните поле')
                return false
            }
            if (!(values['text_template'])) {
                SetTextTemplateNameIsEmpty(true)
                SetHelperTextTextTemplate('Заполните поле')
                return false
            }
        }
    }

    const clearError = () => {
        SetNameTemplateIsEmpty(false)
        SetTextTemplateNameIsEmpty(false)
    }

    useEffect(() => {
        // Title страницы
        document.title = 'Редактировать шаблон сообщения для отправки в WhatsApp - chat-starter.com'  // Title страницы
        // Есть ли токен или нет?
        const token = localStorage.getItem("jwt_access")
        if (token) {
            setJwt('Bearer ' + token)
        } else {
            navigate('/login')
        }
    }, [])

    // [GET] - Получение шаблона сообщения
    useEffect(() => {
        if (jwt && idTemplate) {
            fetchGetOneTemplate(jwt, idTemplate)
                .then((res) => {
                    if (res.template) {
                        setLoading(true)
                        setValues(res.template)
                    } else {
                        setError(res.errorMessageArray)
                        popUpOpen()
                    }
                })
        }
    }, [jwt]);


    return (<>
        {loading ? (<>
            <h3>Редактирование шаблона "{values.name_template}"</h3>
            <PopUp
                handleClose={popUpClose}
                open={popUpVisible}
                title={"Что-то пошло не так 😢"}
            >
                {error}
                <Link href={"/templates_messages"}>
                    Вернуться к списку шаблонов
                </Link>
            </PopUp>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '16px'
            }}>
                <FormControl>
                    <TextField
                        onChange={SetValuesOnChange}
                        sx={{
                            width: {xs: '100%', sm: '380px'},
                            marginBottom: 3
                        }}
                        id="name_template"
                        label="Название шаблона"
                        rows={1}
                        helperText={helperTextNameTemplate}
                        error={NameTemplateIsEmpty}
                        onFocus={clearError}
                        value={values.name_template}
                    />
                    <TextField
                        onChange={SetValuesOnChange}
                        sx={{width: {xs: '100%', sm: '380px'}}}
                        id="text_template"
                        label="Текст сообщения"
                        multiline
                        rows={8}
                        helperText={helperTextTextTemplate}
                        error={TextTemplateNameIsEmpty}
                        onFocus={clearError}
                        value={values.text_template}
                    />
                    <FormControlLabel
                        label="Использовать по умолчанию"
                        control={
                            <Checkbox
                                id="is_default"
                                value={values.is_default}
                                checked={values.is_default}
                                onChange={SetValuesOnChange}
                            />
                        }
                    />
                    <Button
                        onClick={() => {
                            SendForm(values)
                        }}
                        type={"submit"}
                        sx={{
                            marginTop: 3,
                            marginBottom: 1,
                            width: {xs: '100%', sm: 380},
                            height: 56
                        }}
                        variant="contained">Сохранить
                        <DoneIcon sx={{marginLeft: 1}}/>
                    </Button>
                    <Button
                        onClick={handleConfirmPopUpOpen}
                        variant="outlined"
                        // type={"submit"}
                        sx={{
                            marginTop: 1,
                            marginBottom: 2,
                            width: {xs: '100%', sm: 380},
                            height: 56
                        }}
                    >Отменить
                        <CancelOutlinedIcon sx={{marginLeft: 1}}/>
                    </Button>
                </FormControl>
            </Box>

            {/*POP-UP*/}
            <Dialog
                disableScrollLock
                open={confirmPopUpVisible}
                onClose={handleConfirmPopUpClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Вы уверены?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Все изменения будут потеряны.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmPopUpClose}>Нет</Button>
                    <Button href={'/templates_messages'} autoFocus>
                        Да
                    </Button>
                </DialogActions>
            </Dialog>
        </>) : (<Preloader/>)}
    </>);
}

export default EditTemplate;